import { NavigationStart, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UserService } from '@common/services/user.service';
import { MorningstarUtils } from '@common/navigation/globalNav/MorningstarUtils';

@Injectable()
export class RestrictedAccessGuard {
  constructor(private router: Router,
              private userService: UserService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe({
      next: (event: NavigationStart) => {
        // May want to consider using RoutesRecognized as a way to recognize route params (like <route>/:id)
        if (userService.isUserMorningstar()) {
          const canAccessUrl = MorningstarUtils.doesUserHaveAccess(this.userService?.getUserPermissions(), event.url);
          if (!canAccessUrl) {
            const defaultRoute = MorningstarUtils.defaultRoute(this.userService.getUserPermissions());
            if (defaultRoute) {
              this.router.navigate([defaultRoute]);
            } else {
              this.userService.logout('Error');
            }
          }
        }
      }
    });
  }
}
