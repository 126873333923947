export class DocumentUtilities {
    static downloadBlob(blob: Blob, filename: string) {

        const doc = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = doc;
        link.download= filename;

        link.click();

        setTimeout(() =>{
            window.URL.revokeObjectURL(doc);
        }, 100);
    }

    static downloadAggregateBlobNewTab(blob: Blob, filename: string){
        // const doc = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.target = `_blank`;
        link.click();
        window.URL.revokeObjectURL(link.href);
  
    }

    static addCss(cssScriptId: string, href: string, onLoad: () => void) {
        if (!document.getElementById(cssScriptId)) {
            const cssScriptLink = document.createElement('link');
            cssScriptLink.id = cssScriptId;
            cssScriptLink.rel = 'stylesheet';
            cssScriptLink.type = 'text/css';
            cssScriptLink.href = href;
            cssScriptLink.media = 'all';
            cssScriptLink.onload = onLoad;
            document.head.appendChild(cssScriptLink);
        } else {
            onLoad();
        }
    }
}
