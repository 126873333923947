import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from '@common/services/user.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class DocumentService {
  requestOptions = { headers: new HttpHeaders({
    Accept: 'application/pdf, application/json',
    'Content-Type': 'application/json'
  }), responseType: 'blob' as 'blob' };
  genericRequestOptions = { headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }), responseType: 'blob' as 'blob' };

  constructor(private http: HttpClient, private userService: UserService) {}

  getProductDocument(endpoint: string) {
    return this.http.get(endpoint, this.requestOptions);
  }

  getDocumentFromFullUrl(url: string) {
    return this.http.get(url, this.genericRequestOptions);
  }

  getMarketplacePdfExport(exportData: FormData) {
    return this.http.post( environment.hosts.api_gateway + '/api/document-service/product/export', exportData, { responseType: 'blob'} ).pipe(
      catchError(error => {
          return of(null);
      })
    );
  }

  getDocumentInfo(documentKey) {
    return this.http.get(environment.hosts.api_gateway + '/api/document-service/document/' + documentKey);
  }
}
