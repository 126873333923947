import { environment } from '@environments/environment';
import { USER_TOKEN_COOKIE } from './app.component';
import { UserService } from '@common/services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NavigationConstants } from '@constants/navigation.constants';
import { NavService } from '@common/services/nav.service';
import { RestrictedAccessGuard } from '@common/services/restricted-access.guard';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  constructor(private userService: UserService,
              private navService: NavService,
              private cookieService: CookieService,
              private restrictedAccessGuard: RestrictedAccessGuard, // TODO MAI - See if I move this elsewhere - ZF
              private router: Router) {
  }


  initUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment.localDevelopment) {  // try to login via localstorage
        this.userService.loadUserFromLocalStorage().then(() => {
          resolve();
        });
      } else {
        const userToken = this.cookieService.get(USER_TOKEN_COOKIE);
        setTimeout(() => {
          this.cookieService.delete(USER_TOKEN_COOKIE, '/', window.location.hostname.replace('app', ''), true, 'None');
        }, 1000);
        if (userToken) {
          this.getUserInformationFromToken(userToken, resolve);
        } else {
          if (window.location.pathname !== NavigationConstants.LOGIN) {
            this.router.navigate(['login'], {queryParams: {
              r: window.location.href
              }}).then(() => resolve());
          } else {
            resolve();
          }
        }
      }
    });
  }

  private getUserInformationFromToken(userToken, resolve) {
    this.userService.getFullTokenResponseFromAccessToken(userToken).subscribe({
      next: () => {
        if (this.router.url?.includes('login')) {
          this.router.navigate(['dashboard']).then(value => {
            resolve();
          });
        } else {
          resolve();
        }
      }, error: err => {
        this.router.navigate(['login']).then(value => {
          resolve();
        });
      },
    });
  }
}
