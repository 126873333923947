import { BaseFilter } from './base-filter';
import { BaseFilterModel, FilterGroup, GenericFilterTypes } from './base-filter.model';
import { FilterMethods } from './filter-methods.utilities';


export interface TextSearchFilterModel<U extends GenericFilterTypes = string> extends FilterGroup<U> {
  // Overrides
  searchType: 'pre' | 'full' | 'some' | 'end';
}

export class TextSearchFilter<U extends GenericFilterTypes = string> extends BaseFilter<U> {
  // tslint:disable-next-line:variable-name
  constructor(protected _baseFilterModel: TextSearchFilterModel<U>) {
    super(_baseFilterModel);
  }

  applyFilter<T>(dataToBeFiltered: T[]): T[] {
    return FilterMethods.applyTextFilter(this._baseFilterModel.filterValues as any[], dataToBeFiltered, this.baseFilterModel.fieldsToCheck);
  }

  doesFilterPass(dataToBeFiltered: any[]): boolean {
    return FilterMethods.doesTextFilterApply(this._baseFilterModel.filterValues as any[],
      dataToBeFiltered,
      this.baseFilterModel.fieldsToCheck,
      this.baseFilterModel.searchType);
  }

  clearFilters(close = false) {
    if (close) {
      this._baseFilterModel.expanded = false;
    }
    this.selectAll();
  }

  applyFilterView(filters: FilterGroup<any>) {
      for (const filter of filters.filters) {
        const currentFilter = this._baseFilterModel.filters.find(x => x.displayName === filter.displayName);
        if (currentFilter) {
          currentFilter.selected = filter.selected;
        } else {
          this._baseFilterModel.filters.push(filter);
        }
      }
    // This is currently not needed due to cascade changes after this is called in a loop,
    // If we decide to call this function explicitly anywhere we may need a param to trigger the following:
    // if (todoParam) {
    // this.filterValuesChanged(true);
    // }
  }
}
