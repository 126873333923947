import { ColDef, GridOptions } from 'ag-grid-community';

export enum COMPARATOR_TYPES {
  BASIC = 'basic',
  PERCENT_RANGE = 'percent_range',
  DATE = 'date',
  STARTING_NUMBER_ONLY = 'starting_number',
  YES_NO = 'yes_no',
  CUSTOM_AN_TERM = 'custom_an_term',
  CUSTOM_AN_RATE = 'custom_an_rate',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

export interface SortInfo {
  sortName: string;
  dataProperty: string;
  comparatorType: COMPARATOR_TYPES;
  isSorting?: boolean;
  isDefault?: boolean;
  sortDirection?: SortDirection;
}

export interface SortGroup {
  sortDirection?: SortDirection;
  defaultSortDirection?: SortDirection;
  isOpen?: boolean;
  sortIndex: number;
  sortInfo: SortInfo;
  isDefaultGroup?: boolean;
}

export interface SortOptions {
  sortInfos: SortInfo[];
  sortGroups?: SortGroup[];
}

export class SortUtilities {
  static createSortOptions(sortInfos: SortInfo[], sortGroups?: SortGroup[], defaultSortDirection: SortDirection = SortDirection.asc): SortOptions {
    if (sortGroups === null || sortGroups?.length === 0) {
      const findSelectedGroups = sortInfos?.filter((sortInfo: SortInfo) => sortInfo.isSorting);
      if (findSelectedGroups?.length > 0) {
        sortGroups = findSelectedGroups.map((sortOption: SortInfo, index: number) => {
          return {
            isOpen: false,
            sortDirection: defaultSortDirection,
            defaultSortDirection,
            sortIndex: index,
            sortInfo: sortOption
          };
        });
      } else {
        const sortGroup = this.generateSortGroup(sortInfos, defaultSortDirection);
        sortGroups = [sortGroup];
      }
    }
    return {
      sortGroups,
      sortInfos,
    } as SortOptions;
  }

  static addSort(sortOptions: SortOptions, sortDirection: SortDirection) {
    const group = this.generateSortGroup(sortOptions.sortInfos, sortDirection);
    sortOptions.sortGroups.push(group);
  }

  static removeSort(sortOptions: SortOptions, index: number) {
    sortOptions.sortGroups.splice(index, 1);
    for(const sortGroup of sortOptions.sortGroups) {
      sortGroup.sortIndex = sortGroup.sortIndex > 0 ? sortGroup.sortIndex - 1 : 0; // adjust indexes after removing a sort
    }
  }


  static updateSortInfosFromSortGroup(sortOptions: SortOptions) {
    for(const sortInfo of sortOptions.sortInfos) {
      sortInfo.isSorting = false;
    }

    for (let i = sortOptions.sortGroups?.length - 1; i >= 0; i--) {
      const groupSortInfo = sortOptions.sortInfos.find(x => x.sortName === sortOptions.sortGroups[i].sortInfo.sortName);
      if(groupSortInfo) {
        groupSortInfo.isSorting = true;
        sortOptions.sortGroups[i].sortInfo = groupSortInfo;
      } else {
        sortOptions.sortGroups.splice(i, 1);
      }
    }
  }

  static resetSorts(sortOptions: SortOptions) {
    if(!sortOptions) { return; }
    for(const sortInfo of sortOptions.sortInfos) {
      sortInfo.isSorting = false;
    }
    sortOptions.sortGroups = [
      this.generateSortGroup(sortOptions.sortInfos, SortDirection.asc, true)
    ];
  }

  /**
   * Utilty function to take in a saved view the default sortInfo set and apply active sorts appropriately in the case of a mismatch in sortInfos
   * @param baseSortInfos: the full set of sortInfos for a given view/data set
   * @param sortOptions: the sortoptions coming in from a saved view with potentially different/inapplicable sortInfos/groups
   */
  static applySortViewSafely(baseSortInfos: SortInfo[], sortOptions: SortOptions) {
    const newSortInfos = baseSortInfos.map((x: SortInfo) => ({...x, isSorting: sortOptions.sortInfos.find(y => y.sortName === x.sortName)?.isSorting || false}));
    const newSortGroups = sortOptions?.sortGroups?.filter(x => newSortInfos.some(y => y.sortName === x.sortInfo.sortName))
      .map((x: SortGroup) => ({...x, sortInfo: newSortInfos.find(y => y.sortName === x.sortInfo.sortName)}));
    return  { sortGroups: newSortGroups, sortInfos: newSortInfos} as SortOptions;
  }

  /**
   * Generates a sortgroup for a given sortInfoArray. If there is a default it uses it,
   * otherwise it uses the first it finds and sets it as the default
   * if The default is already in use it uses the first available sortinfo
   */
  static generateSortGroup(sortInfos: SortInfo[], defaultSortDirection, initialGroup = false) {
    let sortInfoForGroup = sortInfos.find(sortInfo => sortInfo.isDefault);
    if(!sortInfoForGroup) {
        sortInfoForGroup = sortInfos.find(x => !x.isSorting);
        sortInfoForGroup.isDefault = true;
    } else if(sortInfoForGroup.isSorting) {
      sortInfoForGroup = sortInfos.find(x => !x.isDefault && !x.isSorting);
    }
    if(!sortInfoForGroup) {
      return null;
    }
    sortInfoForGroup.isSorting = true;
    return {
      isOpen: false,
      sortDirection: defaultSortDirection,
      defaultSortDirection,
      sortIndex: initialGroup ? 0 : (sortInfos.filter((obj) => obj.isSorting === true).length) - 1,
      sortInfo: sortInfoForGroup
    } as SortGroup;
  }

  static generateComparator(dataProperty, type: COMPARATOR_TYPES = COMPARATOR_TYPES.BASIC) {
    if (type === COMPARATOR_TYPES.BASIC) {
      return this.basicComparatorAgGrid(dataProperty);
    }
    if (type === COMPARATOR_TYPES.PERCENT_RANGE) {
      return this.percentRangeComparator(dataProperty);
    }
    if (type === COMPARATOR_TYPES.DATE) {
      return this.dateComparator(dataProperty);
    }
    if (type === COMPARATOR_TYPES.STARTING_NUMBER_ONLY) {
      return this.startingNumberOnlyComparatorAgGrid(dataProperty);
    }
    if (type === COMPARATOR_TYPES.YES_NO) {
      return this.yesNoCompareFnAgGrid(dataProperty);
    }

    if (type === COMPARATOR_TYPES.CUSTOM_AN_TERM) {
      return this.customAnTermAgGrid(dataProperty);
    }

    if (type === COMPARATOR_TYPES.CUSTOM_AN_RATE) {
      return this.customAnRateAgGrid(dataProperty);
    }
  }

  static dateComparator(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      return this.dateCompareFn(valA, valB, isInverted);
    };
  }

  static dateCompareFn(valueA, valueB, isInverted: boolean): number {
    const [compareValueA, compareValueB] = [valueA, valueB].map(dateMap => {
      const stringDate = dateMap;
      if (!stringDate) {
        return 0;
      }
      const isPm = stringDate.includes('PM');
      const msTime = new Date(stringDate.replace('AM', '').replace('PM', '')).getTime();
      return isPm ? (msTime + 1000 * 60 * 60 * 12) : msTime;
    });

    if (!compareValueA && !compareValueB || compareValueA === compareValueB) {
      return 0;
    }
    if (compareValueA == null) {
      return isInverted ? -1 : 1;
    }
    if (compareValueB == null) {
      return isInverted ? 1 : -1;
    }
    return compareValueA > compareValueB ? 1 : -1;
  }

  static basicComparatorAgGrid(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      return basicCompareFn(valA, valB, isInverted);
    };
  }

  static startingNumberOnlyComparatorAgGrid(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      const foo = this.startingNumberOnlyCompareFn(valA, valB, isInverted);
      return foo;
    };
  }

  static startingNumberOnlyCompareFn(compareValueA, compareValueB, isInverted: boolean): number {
    if (((compareValueA == null || compareValueA === '' || compareValueA === '-') &&
      (compareValueB == null || compareValueB === ''  || compareValueA === '-')) ||
      compareValueA === compareValueB) {
      return 0;
    } else if (compareValueA == null || compareValueA === '' || compareValueA === '-') {
      return isInverted ? -1 : 1;
    } else if (compareValueB == null || compareValueB === '' || compareValueB === '-') {
      return isInverted ? 1 : -1;
    } else {
      return +(compareValueA.split(' ')[0]) > +(compareValueB.split(' ')[0]) ? 1 : -1;
    }
  }

  static yesNoCompareFnAgGrid(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      return this.yesNoCompareFn(valA, valB, isInverted);
    };
  }

  static customAnTermAgGrid(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      return this.customAnTermCompare(valA, valB, isInverted);
    };
  }

  static customAnRateAgGrid(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      return this.customAnRateCompare(nodeA.data, nodeB.data, dataProperty, isInverted);
    };
  }

  static yesNoCompareFn(compareValueA, compareValueB, isInverted, otherFalseValues: string[]  = ['No', '-', 'N/A']) {

    if (!compareValueB) { compareValueB = 'N/A'; }
    if (!compareValueA) { compareValueA = 'N/A'; }

    if (compareValueA === compareValueB) {
      return 0;
    }

    let xValue = compareValueA ? 1 : -1;
    let yValue = compareValueB ? 1 : -1;
    if (xValue && otherFalseValues?.length > 0) {
      const indexOf = otherFalseValues.indexOf(compareValueA);
      xValue = indexOf > -1 ? (indexOf + 1) * -1 : xValue;
    }

    if (yValue && otherFalseValues?.length > 0) {
      const indexOf = otherFalseValues.indexOf(compareValueB);
      yValue = indexOf > -1 ? (indexOf + 1) * -1 : yValue;
    }

    let compareVal;

    // if x is true, needs to be negative
    // if y is true, needs to be positive
    // same is 0

    if (xValue > yValue) {
      compareVal = -1;
    } else if (yValue > xValue) {
      compareVal = 1;
    }
    return compareVal;
  }

  /**
   * @description Other False Values will be grouped properly based on position in array
   * <br><strong>(indexOf + 2)</strong> is to pad for falsy values being -1<br>
   * <br><strong>*-1</strong> is to convert to a negative value to represent the value that should not be considered as valid string
   * @return
   * falsy would be -1<br>
   * 'No' would be (0 + 2) * -1 = -2<br>
   * '-' would be (1 + 2) * -1 = -3<br>
   * 'N/A' would be (1 + 2) * -1 = -4<br>
   */
  static getValueForFalseValue(value: string | null, otherFalseValues: string[]) {
    let returnValue = -1;
    if (value && otherFalseValues?.length > 0) {
      const indexOf = otherFalseValues.indexOf(value);
      returnValue = indexOf > -1 ? (indexOf + 2) * -1 : returnValue;
    }
    return returnValue;
  }

  static customAnTermCompare(compareValueA, compareValueB, isInverted, otherFalseValues: string[] = ['No', '-', 'N/A']) {

    if (compareValueA === compareValueB) {
      return 0;
    }

    const convertStringToMonths = (value) => {
      if (!value || otherFalseValues.includes(value)) {
        return this.getValueForFalseValue(value, otherFalseValues);
      }

      if (value === 'Annual') {
        return 12;
      }

      if (value === 'Monthly') {
        return 1;
      }

      if (value === 'Daily') {
        // 1 / 28
        return 0.03571428571;
      }

      const regExp = new RegExp(/^(\d+)(?:\s(?:Year)(?:s?))(?:\s(\d+)(?:\s(?:Month)(?:s)))?$/gi);
      const results = regExp.exec(value);
      if (results?.length > 1) {
        const years = results[1];
        const months = results[2] ?? 0;
        return +years * 12 + +months;
      }

      return 0;
    };

    let aInMonths = convertStringToMonths(compareValueA);
    let bInMonths = convertStringToMonths(compareValueB);

    if (aInMonths < 0) {
      aInMonths = isInverted ? aInMonths : aInMonths * -1;
    }

    if (bInMonths < 0) {
      bInMonths = isInverted ? bInMonths * -1 : bInMonths;
    }

    return aInMonths - bInMonths;
  }

  static customAnRateCompare(nodeDataA, nodeDataB, dataProperty, isInverted, otherFalseValues: string[] = ['No', '-', 'N/A']) {
    const getHighestValueWithMisfits = (node): number => {
      const misfitFlatten = node?.misfitRates?.map(misfit => misfit[dataProperty]) ?? [];
      const filteredAndSortedRateValues = [node[dataProperty], ...misfitFlatten]
        .filter(rate => rate != null)
        .sort((a, b) => b - a);
      if (filteredAndSortedRateValues.length === 0) {
        return null;
      }

      if (isInverted) {
        return filteredAndSortedRateValues[filteredAndSortedRateValues.length -1];
      }

      return filteredAndSortedRateValues[0];
    };

    const highestValueA = getHighestValueWithMisfits(nodeDataA);
    const highestValueB = getHighestValueWithMisfits(nodeDataB);

    if (highestValueA === highestValueB) {
      return 0;
    } else if (highestValueA == null) {
      return isInverted ? -1 : 1;
    } else if (highestValueB == null) {
      return isInverted ? 1 : -1;
    }

    return highestValueA > highestValueB ? 1 : -1;
  }


  static percentRangeComparator(dataProperty) {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
      const valA = nodeA.group ? nodeA.aggData[dataProperty]: nodeA.data[dataProperty];
      const valB = nodeB.group ? nodeB.aggData[dataProperty]: nodeB.data[dataProperty];
      return this.percentRangeCompareFn(valA, valB, isInverted);
    };
  }

  static percentRangeCompareFn(compareValueA, compareValueB, isInverted): number {
    if (((compareValueA == null || compareValueA === '') && (compareValueB == null || compareValueB === '')) || compareValueA === compareValueB) {
      return 0;
    }
    if (compareValueA == null || compareValueA === '-' || compareValueA === '') {
      return isInverted ? -1 : 1;
    }
    if (compareValueB == null || compareValueB === '-' || compareValueB === '') {
      return isInverted ? 1 : -1;
    }

    const aValuesArray = compareValueA.replaceAll('%', '').split('-').map(value => +(value?.trim()));
    const bValuesArray = compareValueB.replaceAll('%', '').split('-').map(value => +(value?.trim()));
    const aValues = {
      low: aValuesArray[0],
      high: aValuesArray.length > 1 ? aValuesArray[1] : aValuesArray[0]
    };
    const bValues = {
      low: bValuesArray[0],
      high: bValuesArray.length > 1 ? bValuesArray[1] : bValuesArray[0]
    };

    if (aValues.low !== bValues.low) {
      return aValues.low > bValues.low ? 1 : -1;
    }
    return aValues.high > bValues.high ? 1 : -1;
  }

  static updateSort(sortOptions: SortOptions, gridOptions: GridOptions) {
    const sortStates = [];
    const colDefs: ColDef[] = gridOptions?.api?.getColumnDefs();
    if(!colDefs || colDefs.length === 0 ) { return; }
    const activeSorts =  sortOptions?.sortGroups.filter(sG => sG?.sortInfo?.isSorting);
    if (!activeSorts || activeSorts?.length === 0) {
      colDefs.forEach(colDef => colDef.comparator = null);
    } else {
      for(const [index, sortInfo] of activeSorts?.entries()) {
        colDefs[index].comparator = SortUtilities.generateComparator(sortInfo.sortInfo.dataProperty, sortInfo.sortInfo.comparatorType);
        sortStates.push( {
          colId: colDefs[index].colId,
          sort: sortInfo.sortDirection,
          sortIndex: index
        });
      }
    }
    gridOptions.api.setColumnDefs(colDefs);
    setTimeout(() => {
      gridOptions.columnApi.applyColumnState({state: sortStates, defaultState: {sort: null}});
    });
  }

  /**
   * todo: add back in if desired
   * Takes in the data set and the current sort options and returns a new sortoptions that filters out any sort
   * that isn't applicable with the given data set
   * @param productData: the data your sort are to be applied to
   * @param sortOptions: SortOptions, where SortInfo is the set of --all possible sorts-- for the given data
   */
  // static updateAvailableSortOptions(productData: any[], sortOptions: SortOptions) {
  //   if(!productData || productData.length === 0) { return sortOptions; }
  //   const availableSorts = [];
  //
  //   let totalSorts = sortOptions.sortInfos.map(x => x.dataProperty);
  //   for(const data of productData) {
  //     if(totalSorts.length === 0) { break;}
  //     for(const sortProperty of totalSorts) {
  //       if(data[sortProperty] != null && data[sortProperty] !== '-' && data[sortProperty] !== 'N/A') {
  //         availableSorts.push(sortProperty);
  //       }
  //     }
  //     totalSorts = totalSorts.filter(x => !availableSorts.includes(x));
  //   }
  //   if(availableSorts.length === 0) { return {sortGroups: [], sortInfos: null }; }
  //   const newSortOptions = JSON.parse(JSON.stringify(sortOptions));
  //   newSortOptions.sortInfos = sortOptions.sortInfos.filter(x => availableSorts.includes(x.dataProperty));
  //   (newSortOptions.sortInfos.find(sortInfo => sortInfo.isDefault) ?? newSortOptions.sortInfos[0]).isDefault = true; // set first sortinfo to default is default doesn't already exist
  //
  //   newSortOptions.sortGroups = sortOptions.sortGroups?.filter(x => availableSorts.includes(x.sortInfo.dataProperty));
  //   if(!newSortOptions.sortGroups || newSortOptions.sortGroups?.length === 0) {
  //     const group = this.generateSortGroup(newSortOptions.sortInfos, SortDirection.asc);
  //     newSortOptions.sortGroups = [group];
  //   }
  //   // returning instead of modifying by ref. allows us to call this method to update a component's SortOptions Object as a whole
  //   // which will trigger child component's @Input() setter functions (sort-pills component)
  //   return newSortOptions;
  // }

  // todo: add back in if we want to base things off data
  // static createSortOptionsFromSavedView(sortGroups, sortInfos, productData) {
  //   const applicableSortGroups = sortGroups.filter((group: SortGroup) => {
  //     return sortInfos.some((sortInfoItem: SortInfo) => sortInfoItem.dataProperty === group.sortInfo.dataProperty);
  //   });
  //   // update the sortinfos
  //   for(const sortInfo of sortInfos) {
  //     const group = applicableSortGroups.find(x => x.sortInfo.dataProperty === sortInfo.dataProperty);
  //     if(group) {
  //       sortInfo.isSorting = true;
  //       group.sortInfo = sortInfo;
  //     } else {
  //       sortInfo.isSorting = false;
  //     }
  //   }
  //   return SortUtilities.updateAvailableSortOptions(productData,
  //     SortUtilities.createSortOptions(sortInfos, applicableSortGroups));
  // }
}

export function basicCompareFn(compareValueA, compareValueB, isInverted: boolean): number {
  let compareStrings = false;
  if (typeof compareValueA === 'string' && typeof compareValueB === 'string') {
    compareValueA = compareValueA.toLowerCase();
    compareValueB = compareValueB.toLowerCase();
    compareStrings = true;
  }
  if (((compareValueA == null || compareValueA === '') && (compareValueB == null || compareValueB === '')) || compareValueA === compareValueB) {
    return 0;
  } else if (compareValueA == null || compareValueA === '-' || compareValueA === '') {
    return isInverted ? -1 : 1;
  } else if (compareValueB == null || compareValueB === '-' || compareValueB === '') {
    return isInverted ? 1 : -1;
  } else {
    if (compareStrings) {
      return compareValueA.localeCompare(compareValueB, undefined, {numeric: true, sensitivity: 'base'});
    }

    return compareValueA > compareValueB ? 1 : -1;
  }
}
