import { FilterFields, RangeFilterType, TwoFieldFilterType } from './base-filter.model';
import { get as lodashGet, map as lodashMap } from 'lodash';
import { FilterNumberIs } from './number-input-filter';

export class FilterMethods {
  static fullSearch = (fieldValue, searchValue) => fieldValue === searchValue;
  static endSearch = (fieldValue, searchValue) => fieldValue.endsWith(searchValue);
  static preSearch = (fieldValue, searchValue) => fieldValue.startsWith(searchValue);
  static someSearch = (fieldValue, searchValue) => fieldValue.includes(searchValue);

  static get searchMethodMap() {
    return new Map<string, (fieldValue, searchValue) => boolean>([
      ['pre', this.preSearch],
      ['full', this.fullSearch],
      ['some', this.someSearch],
      ['end', this.endSearch]
    ]);
  }

  static getFieldsToCheck(dataToBeFiltered: any, fieldsToBeFiltered: FilterFields[]) {
    return fieldsToBeFiltered.flatMap(fields => {
      if (typeof fields === 'string') {
        return lodashGet(dataToBeFiltered, fields);
      } else if (fields.arrayObjectField || fields.arrayProperty) {
        const newValue = lodashGet(dataToBeFiltered, fields.arrayProperty);
        if (Array.isArray(newValue)) {
          return lodashMap(newValue, fields.arrayObjectField);
        } else {
          return lodashGet(newValue, fields.arrayObjectField);
        }
      } else if (fields.minField && fields.maxField) {
        const min = +lodashGet(dataToBeFiltered, fields.minField);
        const max = +lodashGet(dataToBeFiltered, fields.maxField);
        return {min, max};
      }
    });
  }

  static applyTextFilter(searchValues: string[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'pre' | 'full' | 'some' | 'end' = 'some') {
    const filterMethod = this.searchMethodMap.get(filterType);
    return dataToBeFiltered.filter(data => {
      return this.findSomeTextMatches(searchValues, data, fieldsToBeFiltered, filterMethod);
    });
  }

  static doesTextFilterApply(searchValues: string[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'pre' | 'full' | 'some' | 'end' = 'some'): boolean {
    const filterMethod = this.searchMethodMap.get(filterType);
    return dataToBeFiltered.some(data => {
      return this.findSomeTextMatches(searchValues, data, fieldsToBeFiltered, filterMethod);
    });
  }

  static doesTextListFilterApply(searchValues: string[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[]) {
    return dataToBeFiltered.some(data => {
      return this.getFieldsToCheck(data, fieldsToBeFiltered).some(fieldValue => {
        return searchValues.includes(fieldValue);
      });
    });
  }

  private static findSomeTextMatches(searchValues: string[], data: any, fieldsToBeFiltered: FilterFields[], filterMethod: (fieldValue, searchValue) => boolean) {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some(dataField => {
      if (dataField && typeof dataField === 'string') {
        return searchValues.some(value => {
          return filterMethod(dataField, value);
        });
      }
    });
  }

  // Yes/No Methods
  static applyYesNoFilter(searchValues: boolean[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], additionalNoValues: string[] = ['N/A', '-']) {
    return dataToBeFiltered.filter(data => {
      return this.findSomeYesNoValues(searchValues, data, fieldsToBeFiltered, additionalNoValues);
    });
  }

  static doesYesNoFilterApply(searchValues: boolean[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], additionalNoValues: string[] = ['N/A', '-']): boolean {
    return dataToBeFiltered.some(data => {
      return this.findSomeYesNoValues(searchValues, data, fieldsToBeFiltered, additionalNoValues);
    });
  }

  private static findSomeYesNoValues(searchValues: boolean[], data: any, fieldsToBeFiltered: FilterFields[], additionalNoValues: string[]) {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some(dataField => {
      return searchValues.some(searchValue => {
        if (!searchValue && (!dataField || additionalNoValues.includes(dataField))) {
          return true;
        } else if (searchValue && dataField && !additionalNoValues.includes(dataField)) {
          return true;
        }
      });
    });
  }


  // Slider Methods
  static applySliderFilter(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    return dataToBeFiltered.filter(data => {
      return this.findSomeSliderRanges(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  static doesSliderRangeFilterApply(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    return dataToBeFiltered.some(data => {
      return this.findSomeSliderRanges(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  static doesSliderPercentRangeFilterApply(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    return dataToBeFiltered.some(data => {
      return this.findSomeSliderPercentRanges(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  private static findSomeSliderPercentRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? true : searchValues.some(value => {
        return this.sliderFilter(value, fieldData * 100);
      });
    });
  }

  private static findSomeSliderRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? true : searchValues.some(value => {
        return this.sliderFilter(value, fieldData);
      });
    });
  }

  private static sliderFilter(searchValue: RangeFilterType, fieldData: number | string) {
    const { min, max, currentFloor, currentCeiling } = searchValue;

    if (currentFloor === min && currentCeiling === max) {
      return true;
    }

    if (currentFloor && currentCeiling) {
      return (fieldData >= currentFloor && fieldData <= currentCeiling);
    } else if (currentFloor && !currentCeiling) {
      return fieldData >= currentFloor;
    } else if (!currentFloor && currentCeiling) {
      return fieldData <= currentCeiling;
    }

    return false;
  }

  static applyNumberInputFilter(inputValue: number, dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: FilterNumberIs): any[] {
    return dataToBeFiltered.filter(data => {
      return this.checkNumberSearchFilter(data, fieldsToBeFiltered, inputValue, filterType);
    })
  }

  static doesNumberInputFilterApply(inputValue: number, dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: FilterNumberIs): boolean {
    return dataToBeFiltered.some(data => {
      return this.checkNumberSearchFilter(data, fieldsToBeFiltered, inputValue, filterType);
    })
  }

  static checkNumberSearchFilter(data, fieldsToBeFiltered, inputValue, filterType) {
    const fieldValues = this.getFieldsToCheck(data, fieldsToBeFiltered);
    if(filterType === FilterNumberIs.GREATER_THAN) {
      return !fieldValues.some(x => x < inputValue);
    } else if(filterType === FilterNumberIs.LESS_THAN) {
      return !fieldValues.some(x => x > inputValue);
    } else if(filterType === FilterNumberIs.BETWEEN) {
      return fieldValues[0] <= inputValue && fieldValues[1] >= inputValue;
    }
    else {
      console.warn('invalid filterType');
      return true;
    }
  }

  // Range Methods
  static applyRangeFilter(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    return dataToBeFiltered.filter(data => {
      return this.findSomeRanges(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  static doesRangeFilterApply(searchValues: RangeFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive'): boolean {
    return dataToBeFiltered.some(data => {
      return this.findSomeRanges(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  private static findSomeRanges(searchValues: RangeFilterType[], data: any, fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some(dataField => {
      const fieldData = this.getNumber(dataField);
      return fieldData == null ? null : searchValues.some(value => {
        return this.rangeFilter(value, fieldData);
      });
    });
  }

  private static rangeFilter(searchValue: RangeFilterType, fieldData: number | string) {
    const { min, max } = searchValue;
    if (min && max) {
      return (fieldData >= min && fieldData <= max);
    } else if (min && !max) {
      return fieldData >= min;
    } else if (!min && max) {
      return fieldData <= max;
    }
    return false;
  }

  private static getNumber(value): number | null {
    if (value == null) {
      return null;
    }

    if (!isNaN(value)) {
      return +value;
    } else if (typeof value === 'string') {
      const numberRegex = /(\d+)./g;
      const match = numberRegex.exec(value);
      if (match && match[0]) {
        return parseFloat(match[0]);
      }
    }
    return null;
  }

  static applySingleRangeFilter(searchValues: TwoFieldFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    return dataToBeFiltered.filter(data => {
      return this.findSomeRanges2(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  static doesSingleRangeFilterApply(searchValues: TwoFieldFilterType[], dataToBeFiltered: any[], fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive' = 'inclusive') {
    return dataToBeFiltered.some(data => {
      return this.findSomeRanges2(searchValues, data, fieldsToBeFiltered, filterType);
    });
  }

  private static findSomeRanges2(searchValues: TwoFieldFilterType[], data: any, fieldsToBeFiltered: FilterFields[], filterType: 'inclusive' | 'exclusive') {
    return this.getFieldsToCheck(data, fieldsToBeFiltered).some((dataField: {min: number, max: number}) => {
      const min = this.getNumber(dataField.min);
      const max = this.getNumber(dataField.max);
      return min == null || max == null ? false : searchValues.some(value => {
        return value.currentVal <= max && value.currentVal >= min;
      });
    });
  }
}
