import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WhiteLabelInfo } from '@common/models/white-label-info';
import { SharedRegexUtility } from '@common/utilities/shared-regex.utility';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {
  @Input() whiteLabelInfo: WhiteLabelInfo;
  disclaimer: {title: string, link: string};

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.whiteLabelInfo?.currentValue) {
      this.mapValue();
    }
  }

  private mapValue() {
    const parsedDisclaimers = Object.entries(this.whiteLabelInfo?.disclaimers)
        .map(([title, link]) => {
          if (!link.match(SharedRegexUtility.urlStartWithNewRoot)) {
            link = `//${link}`;
          }
          return {title, link};
        });

    if (parsedDisclaimers?.length > 0) {
      this.disclaimer = parsedDisclaimers[0];
    } else {
      this.disclaimer = null;
    }
  }
}
