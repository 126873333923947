import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CustomFormValidatorsService {

  private MESSAGES = {
    DATE_TIME: 'Date and Time must be past or current.',
    TIME: 'Time must be past or current.'
  };

  getMessagePrefixByFormControl(formControlName: string) {
    switch (formControlName) {
      case 'prospectusAcknowledgmentDate':
      case 'prospectusAcknowledgmentTime':
        return 'Delivery ';

      case 'orderReceiptDate':
      case 'orderReceiptTime':
        return 'Order Taken ';

      default:
        return '';
    }
  }


  beforeOrEqualToCurrentDate(timeControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value == null) {
        return null; // this validation is not a null checker, that's what required is for
      }
      const returnValue = { invalidDate: this.getMessagePrefixByFormControl(timeControlName) + this.MESSAGES.DATE_TIME };
      const controlDate = moment(control.value, 'YYYY-MM-DD', false);
      if (!controlDate.isValid()) {
        return returnValue;
      }
      const validationDate = moment().format('YYYY-MM-DD');
      if (timeControlName) {
        control.root.get(timeControlName).updateValueAndValidity();
      }
      return controlDate.isAfter(validationDate) ?
        returnValue : null;
    };
  }

  beforeOrEqualToCurrentTime(dateControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateInput = control.root.get(dateControlName).value;
      const controlValue = control.value;
      if (controlValue == null || dateInput == null) {
        return null; // this validation is not a null checker, that's what required is for
      } // using .add(1, 'minutes') to pad for any inconsistencies in time between server/client
      const validationDate = moment.tz(new Date().toISOString(), 'America/New_York').add(1, 'minutes');
      // validationDate.
      const dateInputMoment = moment.tz(new Date(dateInput).toISOString(), 'America/New_York');
      const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
      if (!timePattern.test(controlValue) || dateInputMoment.isAfter(validationDate)) {
        return { invalidDate: this.getMessagePrefixByFormControl(dateControlName) + this.MESSAGES.DATE_TIME };
      }
      if (dateInputMoment.isSame(validationDate, 'day') && dateInputMoment.isSame(validationDate, 'month') && dateInputMoment.isSame(validationDate, 'year')) {
        const validationHour = Number(validationDate.hour());
        const control1DigitHour = Number(controlValue.substring(1, 2));
        const control2DigitHour = Number(controlValue.substring(0, 2));
        if (validationHour < control2DigitHour ||
          ((validationHour === control2DigitHour || validationHour === control1DigitHour) && Number(validationDate.minute()) < Number((controlValue.substring(3, 5))))) {
          return { invalidDate: this.getMessagePrefixByFormControl(dateControlName) + this.MESSAGES.TIME };
        }
      }
      return null;
    };
  }

  constructor() { }
}
