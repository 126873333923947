import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { AnnuityIndicesModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-indices.model';
import { AnnuityCompareModel } from '@product-marketplace/annuity-product/annuity-compare/annuity-compare-component/annuity-compare.component';
import { AnnuityIllustrationParams } from '@product-marketplace/annuity-product/annuity-product-view/annuity-illustration-summary/annuity-illustration-summary.model';
import {
    AnnuityView
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-column-defs/column-defs-annuity-product';

export type AnnuityUserInformation = { oesVendorName: string; permissions: { buyBtnPermission: boolean; configureBtnPermission: boolean; } };

@Injectable({
    providedIn: 'root'
})
export class AnnuityStateService {

    // tslint:disable-next-line:variable-name
    private _annuityBlacklistedFeatures: string[];

    get annuityBlacklistedFeatures(): string[] {
        return this._annuityBlacklistedFeatures;
    }

    set annuityBlacklistedFeatures(value: string[]) {
        this._annuityBlacklistedFeatures = value;
    }

    get AN_EXCHANGE_FEATURE_FLAG() {
        return this.checkForBlacklistedFeature('LumaCompare-1035');
    }

    state: BehaviorSubject<any> = new BehaviorSubject(null);
    age: BehaviorSubject<any> = new BehaviorSubject(null);

    createdSavedConfiguration = new Subject<void>();

    selectedBrokerDealer: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedProductTypes: BehaviorSubject<any> = new BehaviorSubject(null);
    annuityCompanies: BehaviorSubject<any> = new BehaviorSubject(null);
    salesAssistantReps: BehaviorSubject<any> = new BehaviorSubject([]);
    annuityUserInformation: ReplaySubject<AnnuityUserInformation> = new ReplaySubject(1);

    annuityIndices: BehaviorSubject<AnnuityIndicesModel[]> = new BehaviorSubject<AnnuityIndicesModel[]>([]);
    emptyIndices: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    compareData: BehaviorSubject<AnnuityCompareModel> = new BehaviorSubject<AnnuityCompareModel>(null);
    illustrationParamsMap = new Map<string, AnnuityIllustrationParams>();

    annuityViewChange = new ReplaySubject<AnnuityView>(1);

    get brokerDealerShortName(): string {
        return this.selectedBrokerDealer.getValue()?.brokerDealer?.shortname ?? '';
    }

    /**
     * Will return if the value is not found in the list or if the list is empty
     * @usageNotes Expect the result to be the inverse of the value being found since we're checking a blacklist
     * @param feature this is the value defined in the backend
     */
    private checkForBlacklistedFeature(feature) {
        return !(this._annuityBlacklistedFeatures?.length > 0
          && this._annuityBlacklistedFeatures.includes(feature));
    }
}
