export class HCRangeSelectorButtons {
  defaultRangeSelectionButtonStack: Array<any>;
  constructor() {
    this.defaultRangeSelectionButtonStack = [
      {
          type: 'day',
          count: 5,
          text: '5d'
      },
      {
          type: 'month',
          count: 1,
          text: '1m'
      },
      {
          type: 'month',
          count: 3,
          text: '3m'
      },
      {
          type: 'month',
          count: 6,
          text: '6m'
      }, {
          type: 'ytd',
          text: 'YTD'
      }, {
          type: 'year',
          count: 1,
          text: '1y'
      },
      {
        type: 'year',
        count: 2,
        text: '2y'
      },
      {
        type: 'year',
        count: 3,
        text: '3y'
      },
      {
          type: 'year',
          count: 5,
          text: '5y'
      },
      {
        type: 'all',
        text: 'ALL',
        enabled: true,
      }
    ];
  }
}
