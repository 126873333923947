import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AnnuityMapperService, AnnuityRate, AnnuityResponse } from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';
import { UserService } from '@common/services/user.service';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import {
    AnnuityIllustrationSummaryModel,
    AnnuityIllustrationParams
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-illustration-summary/annuity-illustration-summary.model';
import { Utilities } from '@common/utilities/utilities';
import { DiaSpiaRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-configure/dia-spia-configure/dia-spia-request.model';
import * as moment from 'moment';
import { FirelightActivityRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-activity-request.model';
import { AnnuityIndicesModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-indices.model';
import { AnnuityChartDataRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-configure/annuity-chart-data-request.model';
import { AnnuityOrderRequestModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-order-request.model';
import { LifecycleAnnuityProduct } from '@common/models/annuity-product';
import { AnnuityCalendarEvent } from '@common/models/annuity-calendar-event';
import { ANNUITY_USER_TYPES } from '@product-marketplace/annuity-product/annuity-constants';
import { AnnuitySavedProductConfigModel } from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-saved-product-config.model';
import { AnnuityStateService } from '@product-marketplace/annuity-product/annuity-state.service';
import {
    ExchangeScenario, ExchangeSummary, MappedExchangeScenario
} from '@product-marketplace/annuity-product/annuity-product-view/exchange-1035/annuity-exchange.models';


@Injectable({
    providedIn: 'root'
})
export class AnnuityService {
    private readonly ANNUITY_PATH = `api/annuity-service`;
    private readonly ANNUITY_ROOT = `${environment.hosts.api_gateway}/${this.ANNUITY_PATH}`;
    private readonly ANNUITY_SERVICE = `${this.ANNUITY_ROOT}/annuity`;
    private readonly ANNUITY_PRODUCT_URL = `${this.ANNUITY_SERVICE}/products`;
    private readonly ANNUITY_PRODUCT_VIEW_URL = `${this.ANNUITY_SERVICE}/productviews`;
    private readonly FILTERED_PRODUCT_VIEW_URL = `${this.ANNUITY_SERVICE}/filteredproductviews`;
    private readonly ASSIGNED_PRODUCT_VIEWS = `${this.ANNUITY_ROOT}/assignedproductviews`; // uses root
    private readonly ANNUITY_COMPANIES_URL = `${this.ANNUITY_SERVICE}/companies`;
    private readonly ANNUITY_COMPANY_TYPES_URL = `${this.ANNUITY_SERVICE}/companytypes`;
    private readonly ANNUITY_STATES_URL = `${this.ANNUITY_SERVICE}/states`;
    private readonly ANNUITY_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/types`;
    private readonly ANNUITY_COMPANY_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/companyproducttypes`;
    private readonly ANNUITY_USER_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/userproducttypes`;
    private readonly ANNUITY_ILLUSTRATION_PARAMS = `${this.ANNUITY_SERVICE}/illustrations/config`;
    private readonly ANNUITY_ILLUSTRATION_URL = `${this.ANNUITY_SERVICE}/illustrations`;
    private readonly ANNUITY_DIA_SPIA_URL = `${this.ANNUITY_SERVICE}/diaspia`;
    private readonly ANNUITY_DIA_SPIA_ILLUSTRATION_URL = `${this.ANNUITY_DIA_SPIA_URL}/illustration`;
    private readonly ANNUITY_ORDER_HISTORY = `${this.ANNUITY_SERVICE}/orderhistory`;
    private readonly ANNUITY_PRODUCT_CONFIG_HISTORY = `${this.ANNUITY_SERVICE}/productconfig`;
    private readonly ANNUITY_FIRELIGHT_URL = `${this.ANNUITY_ROOT}/oes/fl`; // env/api/annuity-service/oes/fl
    private readonly ANNUITY_FIRELIGHT_SSO = `${this.ANNUITY_SERVICE}/oes/fl/sso`; // TODO?
    private readonly ANNUITY_INDICES_URL = `${this.ANNUITY_SERVICE}/indices`;
    private readonly ANNUITY_INIT_ORDER_EVENT = `${this.ANNUITY_SERVICE}/orderevents`;
    private readonly ANNUITY_OES_VENDORS = `${this.ANNUITY_SERVICE}/oesvendors`;
    private readonly ANNUITY_CARRIER_VENDORS = `${this.ANNUITY_SERVICE}/carriervendors`;
    private readonly ANNUITY_FIRELIGHT_CLONE = `${this.ANNUITY_FIRELIGHT_URL}/activities/clone`;
    private readonly ANNUITY_FIRELIGHT_STATUS = `${this.ANNUITY_FIRELIGHT_URL}/activities/status`;
    private readonly ANNUITY_OUTLIER_INDICES = `${this.ANNUITY_SERVICE}/outliersindices`;
    private readonly ANNUITY_PRODUCT_PROFILE = `${this.ANNUITY_SERVICE}/prodprofile/generate`;
    private readonly ANNUITY_VA_PRODUCT_PROFILE = `${this.ANNUITY_SERVICE}/va/prodprofile`;
    private readonly ANNUITY_IMO_BROKERS = `${this.ANNUITY_SERVICE}/imos/brokerdealers`;

    constructor(private http: HttpClient,
                private userService: UserService,
                private annuityMapperService: AnnuityMapperService,
                private annuityStateService: AnnuityStateService) {
    }

    private productUrlFromVendorUniqueId(vendorUniqueId: string) {
        return `${this.ANNUITY_PRODUCT_URL}/${vendorUniqueId}`;
    }

    private buildFrontierUrl(vendorUniqueId): string {
        return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/frontier`;
    }

    private buildIncomeChartUrl(vendorUniqueId): string {
        return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/income`;
    }

    private buildGrowthChartUrl(vendorUniqueId): string {
        return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/growth`;
    }

    private buildAnalysisUrl(vendorUniqueId): string {
        return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/stackedanalysis`;
    }

    getFilteredProducts(state, age, brokerDealer, isAdmin, productTypes): Observable<AnnuityResponse> {
        // Functionality supports multiple Broker Dealers for Carriers - params/URL are different based on user type
        const url = isAdmin ? this.ASSIGNED_PRODUCT_VIEWS : this.FILTERED_PRODUCT_VIEW_URL;
        const brokerDealerParam = isAdmin ? 'bd' : 'bds';

        let params = new HttpParams()
          .set('state', state)
          .set('age', age);

        if (brokerDealer?.id) {
            params = params.append(brokerDealerParam, brokerDealer.shortname);
        }

        productTypes.forEach(productType => {
            params = params.append('pt', productType);
        });


        return this.getAnnuityUserInformation().pipe(switchMap(annuityUserInformation => {
            return this.http.get(url, {
                params
            }).pipe(map((annuityResponse: any) => {
                return this.annuityMapperService.mapAnnuityResponse(annuityResponse, annuityUserInformation);
            }));
        }));
    }

    getAnnuityUserInformation(): Observable<any> {
        const requests = [
            this.getButtonPermissions(),
            this.getBrokerDealerOes(),
        ];

        return forkJoin(requests).pipe(map(([annuityBtnPermissions, oes]) => {
            const response = {
                oesVendorName: oes?.vendorName,
                permissions: annuityBtnPermissions,
            };
            this.annuityStateService.annuityUserInformation.next(response);
            return {
                annuityBtnPermissions,
                oes
            };
        }));
    }

    getButtonPermissions(): Observable<{ buyBtnPermission: boolean; configureBtnPermission: boolean }> {
        return of(
          {
              configureBtnPermission: this.userService.getUserPermissions().includes(WidgetPermissions.MenuMarketplaceAnnuityConfigureButton),
              buyBtnPermission: this.userService.getUserPermissions().includes(WidgetPermissions.MenuMarketplaceAnnuityBuyButton)
          }
        );
    }

    getAnnuityProductFrontierChartInformation(vendorUniqueId: string, productName): Observable<any> {
        const annuityFrontierChartUrl = this.buildFrontierUrl(vendorUniqueId);
        return this.http.get(annuityFrontierChartUrl).pipe(map((data: any) => {
              const strategiesMap = new Map(Object.entries(data.strategies));
              const formattedEfficientFrontierTooltip = this.annuityMapperService.formatFrontierData(data, productName);
              return {
                  frontier: {
                      strategiesMap,
                      data: formattedEfficientFrontierTooltip
                  }
              };
          }
        ), catchError((err => {
            return of({frontier: null});
        })));
    }

    getAnnuityProductChartInformation(annuityChartDataRequestModel: AnnuityChartDataRequestModel, maxAtznAge: number, growthChartColors: string[]): Observable<any> {
        const requests: Observable<any>[] = [];
        if (annuityChartDataRequestModel.needIncome || annuityChartDataRequestModel.needGrowth) {
            requests.push(this.getAnalysisData(annuityChartDataRequestModel, maxAtznAge, growthChartColors,));
        }

        if (annuityChartDataRequestModel.needFrontier) {
            requests.push(this.getAnnuityProductFrontierChartInformation(annuityChartDataRequestModel.vendorUniqueId, annuityChartDataRequestModel.productName));
        }
        return forkJoin(requests).pipe(map((chartData) => {
            const returnData = chartData.reduce((previous, currentChart) => {
                return {
                    ...previous,
                    ...currentChart
                };
            });

            return returnData;
        }), catchError((err) => {
            return of({
                growth: null,
                income: null,
                message: '',
                frontier: null
            });
        }));
    }

    getAnalysisData(annuityChartDataRequestModel: AnnuityChartDataRequestModel, maxAtznAge, growthChartColors): Observable<any> {
        const analysisUrl = this.buildAnalysisUrl(annuityChartDataRequestModel.vendorUniqueId);
        return this.http.get(analysisUrl, {params: annuityChartDataRequestModel.buildGrowthHttpParams()}).pipe(map((analysis: any) => {
            const mapIncomeToLineChart = (data: any[]) => {
                return !!data
                  ? {
                      series: [{
                          data: data.filter(dt => dt.age < maxAtznAge).map(dt => {
                              return {
                                  x: dt.age,
                                  y: dt.accountValue,
                                  label: dt.formattedAge,
                              };
                          })
                      }]
                  }
                  : null;
            };

            const growth = {
                series: analysis.growthResult.map((strategy, strategyIndex) => {
                    const index = annuityChartDataRequestModel.getIndexFromStrategy(strategy.strategyRateId);
                    const formattedIndex = this.annuityMapperService.formatAnnuityRateFromColumn((index.annuityRate as AnnuityRate), annuityChartDataRequestModel.columns);
                    return {
                        name: index ? (index.annuityRate as AnnuityRate)?.underlieIndex : strategy.strategyRateId,
                        data: strategy.growthData.map(dt => {
                            return {
                                x: dt.age,
                                y: dt.accountValue,
                                formattedIndex,
                                label: dt.formattedAge,
                            };
                        }),
                        color: growthChartColors[strategyIndex % growthChartColors.length]
                    };
                })
            };
            return {
                growth,
                income: mapIncomeToLineChart(analysis?.incomeResult),
                message: analysis?.message
            };
        }));
    }

    getProductGrowthChart(vendorUniqueId: string, httpParams: HttpParams): Observable<any> {
        const annuityGrowthChartUrl = this.buildGrowthChartUrl(vendorUniqueId);
        return this.http.get(annuityGrowthChartUrl, {params: httpParams});
    }

    getProductIncomeChart(vendorUniqueId: string, httpParams: HttpParams, startingAge): Observable<any> {
        httpParams = httpParams.set('startingAge', startingAge);
        const annuityIncomeChartUrl = this.buildIncomeChartUrl(vendorUniqueId);
        return this.http.get(annuityIncomeChartUrl, {params: httpParams});
    }

    getFirelightBaseUrl(): Observable<string> {
        return this.http
          .get(`${this.ANNUITY_FIRELIGHT_URL}/baseurl`, {responseType: 'text'});
    }

    getFireLightToken(brokerDealerName: string = '', repSelected?: string): Observable<any> {
        let params = new HttpParams();
        if(repSelected) {
            params = params.set('username', repSelected);
        }
        if (brokerDealerName) {
            return this.http.post(`${this.ANNUITY_FIRELIGHT_URL}/accesstoken`, brokerDealerName, {params});
        }
        return this.http.get(`${this.ANNUITY_FIRELIGHT_URL}/accesstoken`);
    }


    getFireLightTokeAndNewActivity(requestModel: FirelightActivityRequestModel, needsActivity = true, repSelected?: string): Observable<any> {
        if (needsActivity) {
            return this.createFirelightActivity(requestModel, '', repSelected).pipe(
              map(activity => {
                  return {
                      token: activity.AccessToken.access_token,
                      activityId: activity.ActivityId,
                      oesBdName: activity.OesBdName,
                  };
              })
            );
        } else {
            return this.getFireLightToken(requestModel.oesBrokerDealerName, repSelected).pipe(switchMap((tokenData: any) => {
                return of({
                    token: tokenData.access_token,
                });
            }));
        }
    }

    createFirelightActivity(firelightActivityRequestModel: FirelightActivityRequestModel, token: string = '', repSelected?: string): Observable<any> {
        let headers = new HttpHeaders();
        let params = new HttpParams();
        if (token) {
            headers = headers.set('firelighttoken', token);
        }
        if (repSelected) {
            params = params.set('username', repSelected);
        }
        return this.http.post(`${this.ANNUITY_FIRELIGHT_URL}/activities`, firelightActivityRequestModel, {
            headers,
            params
        });
    }

    getAnnuityPoint(vendorUniqueId, allocations: Array<string>, productName: string): Observable<any> {
        const frontierAllocationURL = `${this.buildFrontierUrl(vendorUniqueId)}/allocation`;
        let httpParams = new HttpParams();
        allocations.forEach(allocation => {
            httpParams = httpParams.append('allocs', allocation);
        });

        return this.http.get(frontierAllocationURL, {params: httpParams}).pipe(
          map((response: any) => {
              const formattedVal = this.annuityMapperService.formatEfficientFrontierTooltip(response, productName);
              // TODO - may be temp
              const usePoint = !!(formattedVal && formattedVal.saKey && formattedVal.y && formattedVal.x && formattedVal.displayVal);
              return {
                  usePoint,
                  point: formattedVal
              };
          }), catchError(err => {
              return of({
                  usePoint: false,
                  point: null
              });
          }));
    }

    getAnnuityUserCompanies(): Observable<any> {
        return this.http.get<any>(this.ANNUITY_COMPANIES_URL).pipe(
          map((companies) => {
              const carriers = companies.filter(company => {
                  return company.role === ANNUITY_USER_TYPES.CARRIER;
              });

              const brokerDealers = companies.filter(company => {
                  return company.role === ANNUITY_USER_TYPES.BROKER_DEALER;
              });

              return {
                  carriers,
                  brokerDealers
              };
          }),
          catchError((err) => {
              return of({});
          }));
    }

    getCompanyTypes(): Observable<string> {
        return this.http.get(this.ANNUITY_COMPANY_TYPES_URL, {responseType: 'text'})
          .pipe(catchError((err) => {
              return of('');
          }));
    }

    getBrokerDealerOes(): Observable<any> {
        return this.http.get(this.ANNUITY_OES_VENDORS).pipe(
          catchError(err => {
              return of({});
          })
        );
    }

    getBrokerDealerOesForProduct(uniqueVendorId: string): Observable<any> {
        return this.http.get(`${this.ANNUITY_OES_VENDORS}/${uniqueVendorId}`).pipe(
          catchError(err => {
              return of({});
          })
        );
    }

    getCarriersFromVendor(vendor: string): Observable<any> {
        return this.http.get(`${this.ANNUITY_CARRIER_VENDORS}/${vendor}`);
    }

    getCarrierInfoFromVendor(vendor: string, carrier: string): Observable<any> {
        return this.http.get(`${this.ANNUITY_CARRIER_VENDORS}/${vendor}/${carrier}`);
    }

    getAnnuityStates(): Observable<string[]> {
        return this.http.get<string[]>(this.ANNUITY_STATES_URL).pipe(catchError((err) => {
            return of([]);
        }));
    }

    getAnnuityProductTypes(): Observable<string[]> {
        return this.http.get<string[]>(this.ANNUITY_PRODUCT_TYPES_URL).pipe(catchError((err) => {
            return of([]);
        }));
    }

    getAnnuityUserProductTypes(isAdminOrCarrier): Observable<string[]> {
        const url = isAdminOrCarrier ? this.ANNUITY_COMPANY_PRODUCT_TYPES_URL : this.ANNUITY_USER_PRODUCT_TYPES_URL;
        return this.http.get<string[]>(url);
    }

    getBatchAnnuityIllustrationParams(vendorUniqueIds: string[]): Observable<AnnuityIllustrationParams[]> {
        const batchRequests = vendorUniqueIds.map(vendorUniqueId => this.getAnnuityIllustrationParams(vendorUniqueId));

        return forkJoin(batchRequests);
    }

    getAnnuityIllustrationParams(vendorUniqueId): Observable<AnnuityIllustrationParams> {
        // Check if params are cached locally
        if (this.annuityStateService.illustrationParamsMap.has(vendorUniqueId)) {
            return of(this.annuityStateService.illustrationParamsMap.get(vendorUniqueId));
        }

        const params = new HttpParams()
          .set('cusip', vendorUniqueId);

        return this.http.get<AnnuityIllustrationParams>(this.ANNUITY_ILLUSTRATION_PARAMS, {
            params
        }).pipe(map((annuityIllustrationParams: AnnuityIllustrationParams) => {
            const annuityIllustrationParamsWithId: AnnuityIllustrationParams = {
                ...annuityIllustrationParams,
                vendorUniqueId
            };

            this.annuityStateService.illustrationParamsMap.set(vendorUniqueId, annuityIllustrationParamsWithId);

            return annuityIllustrationParamsWithId;
        }), catchError(error => of(null)));
    }

    getAnnuityIllustration(summary: AnnuityIllustrationSummaryModel): Observable<any> {
        let params = new HttpParams()
          .set('cusip', summary.cusip)
          .set('agentName', summary.agentName)
          .set('stateCode', summary.state || 'OH')
          .set('clientName', summary.name)
          .set('clientAge', summary.age || 55)
          .set('clientSex', summary.sex || 'Male')
          .set('premium', Utilities.stripCurrencyFormatting(summary.initialPremium) || '100000')
          .set('type', summary.type)
          .set('vendor', summary.annuityParams?.vendor);

        if (summary.incomeStartAge) {
            params = params.set('incomeStartAge', summary.incomeStartAge);
        }

        if (summary.incomeRiderCode) {
            params = params.set('incomeRiderCode', summary.incomeRiderCode);
        }


        summary.allocations.map(allocation => allocation.data.rateKey + ',' + allocation.allocation).forEach(alloc => {
            params = params.append('alocs', alloc);
        });

        const httpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/pdf')
          .set('Accept', 'application/pdf');

        return this.http.get(this.ANNUITY_ILLUSTRATION_URL,
          {headers: httpHeaders, params, responseType: 'blob'})
          .pipe(map(data => {
              return data;
          }), catchError((err) => {
              return of(null); // TODO
          }));
    }

    getDiaSpiaOptions(diaSpiaRequest: DiaSpiaRequestModel, brokerDealer): Observable<any> {
        return this.http.get(this.ANNUITY_DIA_SPIA_URL, {
            params: diaSpiaRequest.buildHttpParams()
        }).pipe(map((optionsResponse: any) => {
            optionsResponse?.forEach((option) => {
                option.income = Utilities.formatCurrency(option.income);
                option.taxablePortion = Utilities.formatCurrency(option.taxablePortion);
                if (option.guaranteedEnd != null) {
                    const d = moment(new Date(option.guaranteedEnd));
                    option.guaranteedEnd = d.isValid() ? d.format('MMMM DD, YYYY') : option.guaranteedEnd;
                }
            });
            return optionsResponse;
        }), catchError((err) => {
            const errMsg = typeof err === 'string' ? err : 'There was an error processing this request';
            return throwError(errMsg);
        }));
    }

    getDiaSpiaIllustration(illustrationId, brokerDealer): Observable<Blob> {
        const illustrationUrl = `${this.ANNUITY_DIA_SPIA_ILLUSTRATION_URL}/${illustrationId}`;
        const httpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/pdf')
          .set('Accept', 'application/pdf');
        let httpParams = new HttpParams();
        if (brokerDealer) {
            httpParams = httpParams.set('bd', brokerDealer);
        }
        return this.http.get(illustrationUrl, {params: httpParams, headers: httpHeaders, responseType: 'blob'})
          .pipe(catchError((err) => {
              return of(null); // TODO
          }));
    }

    // TODO - Fix
    private createMockedData = (observer) => {
        setTimeout(() => {
            const generatedTestData = [];
            for (let i = 1; i <= 100; i++) {
                const obj = {};
                const pad = Array(Math.max(3 - String(i).length + 1)).join('0') + i;
                Object.keys(obj).forEach((key) => {
                    if (typeof obj[key] === 'string') {
                        obj[key] = `${obj[key]} ${pad}`;
                    }
                });

                generatedTestData.push(obj);
            }
            observer.next(generatedTestData);
        }, 1200);

        return {
            unsubscribe() {
            }
        };
    }

    getAnnuityOrderHistory(brokerDealer, input): Observable<any> {
        let params = new HttpParams();
        if (brokerDealer) {
            params = params.set('bd', brokerDealer.shortname);
        }

        if (input?.isoStart && input?.isoEnd) {
            params = params.set('startDate', input.isoStart);
            params = params.set('endDate', input.isoEnd);
        }

        return this.http.get(this.ANNUITY_ORDER_HISTORY, {params}).pipe(
          map((data: any[]) => this.annuityMapperService.mapAnnuityOrderEventData(data)),
          catchError(err => {
              return of(null);
          })
        );
    }

    getAnnuityIndices(): Observable<AnnuityIndicesModel[]> {
        return this.http.get<AnnuityIndicesModel[]>(this.ANNUITY_INDICES_URL).pipe(
          map((annuityIndices: AnnuityIndicesModel[]) => {
              return this.annuityMapperService.mapAnnuityIndices(annuityIndices);
          }),
          catchError(err => of([]))
        );
    }

    createInitialOrderEvent(body: AnnuityOrderRequestModel): Observable<any> {
        return this.http.post(this.ANNUITY_INIT_ORDER_EVENT, body);
    }

    getLifecycleProducts(bd: string): Observable<{ lifeCycleProducts: LifecycleAnnuityProduct[], demoFlag: boolean }> {
        let params = new HttpParams();
        if (bd) {
            params = params.set('bd', bd);
        }
        return this.http.get<{ lifeCycleProducts: LifecycleAnnuityProduct[], demoFlag: boolean }>(this.ANNUITY_SERVICE + '/lifecycleproducts', {params});
    }

    disableOrderEvent(reference: string): Observable<any> {
        const params = new HttpParams()
          .set('reference', reference);
        return this.http.post(this.ANNUITY_INIT_ORDER_EVENT + '/disable', null, {params});
    }


    getLifecycleEvents(startDate: Date, endDate: Date, bd: string): Observable<{ calendarEvents: AnnuityCalendarEvent[], eventTypes: string[], demoFlag: boolean }> {
        let params = new HttpParams()
          .set('startdate', Utilities.getISODateWithoutTime(startDate))
          .set('enddate', Utilities.getISODateWithoutTime(endDate));

        if (bd) {
            params = params.set('bd', bd);
        }

        return this.http.get<{ calendarEvents: AnnuityCalendarEvent[], eventTypes: string[], demoFlag: boolean }>(this.ANNUITY_SERVICE + '/lifecyclevents', {params});
    }

    getLifecycleData(policyNumber: string): Observable<any> {
        const params = new HttpParams()
          .set('policy', policyNumber);

        return this.http.get<any>(this.ANNUITY_SERVICE + '/lifecycledata', {params});
    }

    firelightSingleSignOn(activity, oesBrokerDealerName): Observable<any> {
        let params = new HttpParams().set('activity', activity);

        if (oesBrokerDealerName) {
            params = params.set('bd', oesBrokerDealerName);
        }

        return this.http.get(this.ANNUITY_FIRELIGHT_SSO, {params});
    }

    cloneActivity(requestBody, brokerDealerName: string): Observable<any> {
        return this.getFireLightToken(brokerDealerName).pipe(switchMap(token => {
            const headers = new HttpHeaders().set('firelighttoken', token.access_token);
            return this.http.post(this.ANNUITY_FIRELIGHT_CLONE, requestBody, {headers}).pipe(map(resp => {
                return {
                    token,
                    resp
                };
            }));
        }));
    }

    getLatestAnnuityOrderStatus(reference: string, brokerDealerName: string): Observable<any> {
        return this.getFireLightToken(brokerDealerName).pipe(switchMap(token => {
            const params = new HttpParams()
              .set('activityId', reference);
            const headers = new HttpHeaders().set('firelighttoken', token.access_token);
            return this.http.get(this.ANNUITY_FIRELIGHT_STATUS, {params, headers}).pipe(
              map((data: any[]) => this.annuityMapperService.mapAnnuityOrderEventData(data)),);
        }));
    }

    getEmptyIndices(): Observable<string[]> {
        return this.http.get<string[]>(this.ANNUITY_OUTLIER_INDICES).pipe(catchError((err) => {
            return of([]);
        }));
    }

    generateProductProfile(isVa: boolean, vendorUniqueId: string) {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/pdf')
          .set('Accept', 'application/pdf');
        let params = new HttpParams().set('vuid', vendorUniqueId);
        params = params.set('state', this.annuityStateService.state?.getValue());
        if (this.annuityStateService.selectedBrokerDealer?.getValue()) {
            params = params.set('bd', this.annuityStateService.brokerDealerShortName);
        }

        if (isVa) {
            return this.http.get(this.ANNUITY_VA_PRODUCT_PROFILE, {params, headers, responseType: 'blob'});
        } else {
            return this.http.get(this.ANNUITY_PRODUCT_PROFILE, {params, headers, responseType: 'blob'});
        }
    }

    retrieveProductConfiguration(brokerDealer): Observable<any> {
        let params = new HttpParams();
        if (brokerDealer) {
            params = params.set('desk', brokerDealer.shortname);
        }

        return this.getAnnuityUserInformation().pipe(switchMap(annuityUserInformation => {
            return this.http.get(this.ANNUITY_PRODUCT_CONFIG_HISTORY, {
                params
            }).pipe(map((annuityResponse: any) => {
                return annuityResponse.map(response => {
                    return {
                        ...this.annuityMapperService.mapSavedProductConfig(response),
                        ...annuityUserInformation.annuityBtnPermissions,
                        oes: annuityUserInformation.oes
                    };
                });
            }), catchError(err => {
                return of([]);
            })
            );
        }));
    }

    saveProductConfiguration(annuitySummaryModel: AnnuitySavedProductConfigModel) {
        const savedConfig = {
            type: annuitySummaryModel.type === 'Joint' ? 'J' : 'S',
            pcn: annuitySummaryModel.productConfigurationName,
            vui: annuitySummaryModel.vui,
            name: annuitySummaryModel.productName,
            carr: annuitySummaryModel.carrier,
            prm: parseInt(annuitySummaryModel.premium.replace(/[^0-9\.]+/g, ''), 10),
            isd: annuitySummaryModel.indexStartDate != null ? Utilities.getISODateWithoutTime(annuitySummaryModel.indexStartDate) : null,
            ied: annuitySummaryModel.indexEndDate != null ? Utilities.getISODateWithoutTime(annuitySummaryModel.indexEndDate) : null,
            g: annuitySummaryModel.gender.charAt(0),
            st: annuitySummaryModel.state,
            pa: annuitySummaryModel.applicantPurchaseAge,
            ral: annuitySummaryModel.rateAllocations,
            isa: annuitySummaryModel.incomeStartAge,
            dbrid: annuitySummaryModel.dbRiderId,
            irid: annuitySummaryModel.incomeRiderId,
            gj: annuitySummaryModel.jointApplicantGender?.charAt(0),
            paj: annuitySummaryModel.jointApplicantPurchaseAge,
            isaj: annuitySummaryModel.jointIncomeStartAge,
            id: annuitySummaryModel.id,
            irn: annuitySummaryModel?.incomeRiderName,
            dbrn: annuitySummaryModel?.dbRiderName,
            at: annuitySummaryModel?.accountType,
            cn: annuitySummaryModel?.clientNeed,
            sy: annuitySummaryModel?.surrenderYear,
            cusip: annuitySummaryModel.cusip
        };

        if (annuitySummaryModel.id) {
            return this.http.put(this.ANNUITY_PRODUCT_CONFIG_HISTORY, savedConfig, {responseType: 'text'});
        } else {
            return this.http.post(this.ANNUITY_PRODUCT_CONFIG_HISTORY, savedConfig, {responseType: 'text'});
        }
    }

    getFilteredProductSingleView(vendorUniqueId, state, bdShortname?): Observable<any> {
        let params = new HttpParams()
          .set('state', state);
        if (bdShortname) {
            params = params.set('bd', bdShortname);
        }

        return this.http.get(this.ANNUITY_SERVICE + '/filteredproductviews/' + vendorUniqueId, {params});
    }

    analyticalProductComparison(requestBody): Observable<any> {
        return this.http.post(`${this.ANNUITY_PRODUCT_URL}/analytical`, requestBody)
          .pipe(catchError((err) => {
              return of([]);
          }));
    }

    exportProductComparison(requestBody): Observable<any> {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
        return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/comparison`, requestBody, {
            headers,
            responseType: 'blob'
        });
    }

    exportProductRates(requestBody): Observable<any> {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
        return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/ratesviewexport`, requestBody, {
            headers,
            responseType: 'blob'
        });
    }

    exportProductComparisonFiaRila(requestBody): Observable<any> {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
        return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/comparison/fiarila`, requestBody, {
            headers,
            responseType: 'blob'
        });
    }

    exportVaProductComparison(vuids: string[], bd?: string): Observable<any> {
        let params = new HttpParams();
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');


        vuids.forEach(vuid => {
            params = params.append('vuids', vuid);
        });

        if (bd) {
            params = params.set('bd', bd);
        }
        return this.http.get(`${this.ANNUITY_SERVICE}/va/prodprofile/comparison`, {
            headers,
            params,
            responseType: 'blob'
        });
    }

    get1035AnalysisData(request: any, exchangeInformation: any): Observable<MappedExchangeScenario> {
        return this.http.post<any>(`${environment.hosts.api_gateway}/api/annuity-service/annuity/exchange`, request)
          .pipe(map(response => {
              return this.annuityMapperService.mapExchangeData(response.results, exchangeInformation);
          }));
    }

    get1035AnalysisData_DUMMY(): Observable<any> {
        return this.http.post<any>(`${environment.hosts.api_gateway}/api/annuity-exchange-service/annuityexchange/1035ex`, '')
          .pipe(map(response => {
              const mockedResponse = response?.results.map((result, index) => {
                  const randomSummary = () => {
                      const summary = {
                          dbPaymentEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
                          incomeEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
                          feesTotalEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
                          incomeAnnualEv: Math.floor(Math.random() * (55555 - 2000) + 2000)
                      } as ExchangeSummary;
                      summary.totalEv = summary.incomeEv + summary.dbPaymentEv;
                      return summary;
                  };
                  if (result.scenario === 'Zero') {
                      result.scenario = 'Minimum';
                  }
                  return {
                      exchangeInSummary: randomSummary(),
                      exchangeOutSummary: randomSummary(),
                      ...result as ExchangeScenario,
                  };
              });

              return this.annuityMapperService.mapExchangeData(mockedResponse, {
                  carrierOut: 'Mock Carrier Out',
                  carrierIn: 'Mock Carrier In'
              });
          }));
    }

    export1035Analysis(requestBody): Observable<any> {
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
        return this.http.post(`${this.ANNUITY_SERVICE}/exchangeanalysis/export`, requestBody, {
            headers,
            responseType: 'blob'
        });
    }

    getBlackListedFeatures(): Observable<string[]> {
        if (this.annuityStateService.annuityBlacklistedFeatures) {
         return of(this.annuityStateService.annuityBlacklistedFeatures);
        }

        return this.http.get<string[]>(`${this.ANNUITY_SERVICE}/featureblacklist`).pipe(map(blacklistedFeatures => {
            this.annuityStateService.annuityBlacklistedFeatures = blacklistedFeatures;
            return blacklistedFeatures;
        }), catchError(err => {
            return of(null);
        }));
    }

    getUsersForSalesAssistant(username: string): Observable<any[]> {
        const params = new HttpParams().set('salesAssistant', username );
        return this.http.get<any[]>(this.ANNUITY_SERVICE + '/salesAssistant', {params});
    }

    getImoBrokers(): Observable<string[]>  {
        return this.http.get<string[]>(this.ANNUITY_IMO_BROKERS);
    }
}
