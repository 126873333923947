import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { GlobalProductSearchComponent } from '@common/global-product-search/global-product-search.component';
import { GlobalBreadCrumbComponent } from '@common/global-bread-crumb/global-bread-crumb.component';
import { DialogProductInvalidComponent } from '@common/dialog-product-invalid/dialog-product-invalid.component';
import { ConfirmOrCancelModalComponent } from './confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import { FileDragAndDropComponent } from './file-drag-and-drop/file-drag-and-drop.component';
import { DragAndDropDirective } from './file-drag-and-drop/drag-and-drop.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SimpleTooltipDialogComponent } from './simple-tooltip-dialog/simple-tooltip-dialog.component';
import { NumberFormatterDirective } from '@common/directives/number-formatter.directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { OutlineGapDirective } from '../outline-gap.directive';
import { MultipleResultsDialogComponent } from '@common/global-product-search/multiple-results-dialog/multiple-results-dialog.component';


@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    RouterModule,
    NgxSliderModule,
    NgSelectModule
  ],
  declarations: [
    DialogProductInvalidComponent,
    GlobalBreadCrumbComponent,
    GlobalProductSearchComponent,
    ConfirmOrCancelModalComponent,
    FileDragAndDropComponent,
    DragAndDropDirective,
    NumberFormatterDirective,
    ConfirmDialogComponent,
    SimpleTooltipDialogComponent,
    OutlineGapDirective,
    MultipleResultsDialogComponent
  ],
  exports: [
    DialogProductInvalidComponent,
    GlobalBreadCrumbComponent,
    GlobalProductSearchComponent,
    ConfirmOrCancelModalComponent,
    ConfirmDialogComponent,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    RouterModule,
    FileDragAndDropComponent,
    DragAndDropDirective,
    NumberFormatterDirective,
    NgxSliderModule,
    NgSelectModule,
    OutlineGapDirective
  ]
})
export class ProductToolsModule { }
