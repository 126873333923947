import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MarketplaceOrderData } from '@product-marketplace/common/models/marketplace-order';
import { OrderApprovalResults } from '@product-marketplace/common/models/OrderApprovalResults';
import { OrderBuyerList } from '@product-marketplace/common/models/order-buyer';
import { OrderDetails } from '@product-marketplace/common/models/order-details';
import { OrderForm } from './models/order-form.model';
import { OrderFile } from '@product-marketplace/common/models/order-file';
import { OrderFileForm } from './models/order-file-form.model';
import { OrderFormDialogComponent } from './order-form-dialog/order-form-dialog.component';
import { OrderInventoryProgram } from '@product-marketplace/common/models/order-inventory-program';
import { OrderTradeDesks } from '@product-marketplace/common/models/order-tradedesks';
import { State } from '@common/models/states';
import { OrderFormDynFieldService } from './order-form-dyn-field.service';
import { CustomFormValidatorsService } from '@product-marketplace/common/custom-form-validators.service';
import { SpinnerService } from '@common/services/spinner.service';
import { MarketplaceProductService } from '@common/services/marketplace-product.service';
import { MarketplaceStateService } from '@product-marketplace/marketplace-state.service';
import { OrderApprovalActions } from '@product-marketplace/common/models/order-approval-actions';
import { stpActiveStatus } from './order-purchase-dialog/order-purchase-dialog.component';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { ApprovalType } from '@product-marketplace/structured-products/structured-product-orders/approval-type.enum';
import { catchError, map } from 'rxjs/operators';

// book building consts
export const BOOK_BUILDING = 'BOOK_BUILDING';
export const ISSUER_CONFIRMED = 'ISSUER_CONFIRMED';
export const PENDING = 'PENDING';
@Injectable({
    providedIn: 'root'
})
export class OrderFormService implements OnDestroy {
    readonly widgetPermissions = WidgetPermissions;
    waitingOnApiCall = false;
    alive = true;
    approvalType: ApprovalType = ApprovalType.NONE;
    productId: any;
    cusip: string;
    cusipLabel: string;
    orderId: number;
    programId: number;
    tradeDeskId: number;

    buyersSelect: OrderBuyerList[];
    programSelect: OrderInventoryProgram[];
    tradeDeskSelect: OrderTradeDesks[];

    buyersSub: Subscription;
    cancelled = false;
    configOptions: any = {};
    data: any;
    dynamicFieldSpecs: any[];
    dynamicFieldsList = [];     // list of dynamic fields after walking through the field spec
    fileAddingAction = false;
    formTemplateRef: any;
    formCopyOnEdit: FormGroup;
    mainFormChanged = true;
    private orderForm: BehaviorSubject<FormGroup | undefined>;
    OrderForm$: Observable<FormGroup>;
    private orderApproval: BehaviorSubject<OrderApprovalResults[]>;
    orderApproval$: Observable<OrderApprovalResults[]>;
    private orderApprovalActions: BehaviorSubject<OrderApprovalActions[]>;
    $orderApprovalActions: Observable<OrderApprovalActions[]>;

    private orderFormData: Subject<any> = new Subject<any>();
    $orderFormData: Observable<any> = this.orderFormData.asObservable();

    orderDetailsData: any;
    selectedTab = 0;
    states: State[] = [];
    stpConfigActive: stpActiveStatus;
    updateCopyFormBool = true;
    userPermissions: string[] = [];

    constructor(private fb: FormBuilder,
                private marketplaceProductService: MarketplaceProductService,
                private marketplaceStateService: MarketplaceStateService,
                private dialog: MatDialog,
                private orderFormDynFieldService: OrderFormDynFieldService,
                private customFormValidatorService: CustomFormValidatorsService,
                private spinnerService: SpinnerService) { }

    initFormWithDetails(data: MarketplaceOrderData, newForm: boolean) {
        if (data.buyerList.length === 1) {
            data.orderDetails.buyerId = data.buyerList[0].id;
        }
        this.data = data;
        this.orderFormDynFieldService.fullPayloadData = data;
        this.orderFormDynFieldService.orderId = data.orderDetails.orderId;
        this.productId = data.orderDetails.productId;
        this.tradeDeskId = data.orderDetails.tradeDeskId;
        this.programId = data.orderDetails.programId;
        this.orderId = data.orderDetails.orderId;
        this.orderDetailsData = data.orderDetails;
        this.dynamicFieldSpecs = data.orderConfigSpecifications;
        this.tradeDeskSelect = data.regionalTradeDesks;
        this.buyersSelect = data.buyerList;

        if (data.orderDetails.registrationType && data.orderDetails.registrationType.toLowerCase() === 'reg s') {
            this.cusip = data.orderDetails.ISIN;
            this.cusipLabel = 'ISIN';
        } else {
            this.cusip = data.orderDetails.cusip;
            this.cusipLabel = 'CUSIP';
        }

        if (this.userPermissions.includes(this.widgetPermissions.MarketplaceAdminFields) || this.userPermissions.includes(this.widgetPermissions.MarketplacePmFields)) {
            this.programSelect = data.programs;
            this.tradeDeskSelect = data.regionalTradeDesks;
        } else {
            this.programSelect = Array.of(data.programs.find(x => x.id === data.orderDetails.programId));
            if (data.orderDetails.regionalTradeDeskId) {
                this.tradeDeskSelect = Array.of(data.regionalTradeDesks.find(x => x.id === data.orderDetails.regionalTradeDeskId));
            }
        }

        if (data.orderDetails.orderApprovalResults && data.orderDetails.orderApprovalResults[0]) {
            if (!this.orderApproval) {
                this.orderApproval = new BehaviorSubject(data.orderDetails.orderApprovalResults);
                this.orderApproval$ = this.orderApproval.asObservable();
            } else {
                this.orderApproval.next(data.orderDetails.orderApprovalResults);
            }
        }
        if(data.orderDetails.orderApprovalActions && data.orderDetails.orderApprovalActions[0]) {
            if(!this.$orderApprovalActions) {
                this.orderApprovalActions = new BehaviorSubject(data.orderDetails.orderApprovalActions);
                this.$orderApprovalActions = this.orderApprovalActions.asObservable();
            } else {
                this.orderApprovalActions.next(data.orderDetails.orderApprovalActions);
            }
        }
        const form = this.fb.group(
            new OrderForm(new OrderDetails(data.orderDetails), this.fb));
        if (this.dynamicFieldSpecs) {
            this.dynamicFieldsList = this.orderFormDynFieldService.addDynamicFields(this.orderDetailsData, form, this.dynamicFieldSpecs, this.configOptions);
        }

        if(data?.orderDetails?.orderApprovalResults?.length > 0) {
            this.approvalType = data.orderDetails.orderApprovalResults[0].approvalType;
            if(this.approvalType === ApprovalType.BOOK_BUILDING && data.orderDetails.orderApprovalResults[0].orderApprovalResult === ISSUER_CONFIRMED) {
                form.disable();
                form.get('accountNumber').enable();
                form.get('accountNumber').updateValueAndValidity();
            }
        } else {
            this.approvalType = ApprovalType.NONE;
        }
        if (newForm) {
            this.orderForm = new BehaviorSubject(form);
        } else {
            this.orderForm.next(form);
        }
        this.OrderForm$ = this.orderForm.asObservable();
    }

    updateSelectFields(programId: number) { /* Update the select dropdowns associated with changing the program */
        this.programId = programId;
        this.buyersSub = this.marketplaceProductService.refreshValues(this.productId, programId, this.tradeDeskId).subscribe((data: any) => {
            if( data.spec ) {
                this.dynamicFieldSpecs = data.spec;
            }
            this.stpConfigActive = data.stpActive ? stpActiveStatus.ACTIVE : stpActiveStatus.INACTIVE;
            this.buyersSelect = data.buyerList;
            const currentOrder = this.orderForm.getValue();
            currentOrder.get('firmNumber').setValue(data.contraList[0] || null);
            this.orderForm.next(currentOrder);
        });
    }

    showRiskError() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            success: true,
            title: 'Product Not Ranked',
            message: `The product risk level for this product is not currently ranked in Luma.
            Please contact the Support Team at 844-844-5862 or support@lumafintech.com to resolve this issue. We apologize for any inconvenience.`
        };
        this.dialog.open(OrderFormDialogComponent, dialogConfig);
    }

    deleteOrder() {
        if (!this.canEdit()) {
            window.alert('This user is not able to edit orders. To allow cancelling, update this user\'s entitlements.');
        } else {
            const dialogConfig: MatDialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
            dialogConfig.data = {
                message: 'Are you sure you want to cancel this order?',
                title: 'Cancel Order',
            };
            const dialogRef = this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
            dialogRef.afterClosed()
                .subscribe((confirmed) => {
                    if (confirmed === 'confirm') {
                        this.waitingOnApiCall = true;
                        this.marketplaceProductService.deleteOrder(this.orderId).subscribe(res => {
                            this.waitingOnApiCall = false;
                            this.cancelled = true;
                            this.marketplaceStateService.$orderCancelled.next(this.orderId);
                        }, err => {
                            this.waitingOnApiCall = false;
                            window.alert('Cannot cancel order at this time, Try again later.');
                        });
                    }
                });
        }
    }

    updateCopyForm(newForm: OrderDetails) {
        const form = this.fb.group(
            new OrderForm(new OrderDetails(newForm), this.fb));
        if (this.dynamicFieldSpecs) {
            // todo: do we need to set dynamicFieldsList?
            this.orderFormDynFieldService.addDynamicFields(this.orderDetailsData, form, this.dynamicFieldSpecs, this.configOptions);
        }
        this.formCopyOnEdit = form;
        this.mainFormChanged = false;
    }

    updateForm(orderId: number) {
        this.marketplaceProductService.getOrderFormEdit(orderId).subscribe((res: any) => {
            this.orderFormData.next(res);
            this.updateCopyForm(res);
            this.updateCopyFormBool = true;
            this.initFormWithDetails(res, false);
        });
    }

    newOrder() {
        this.spinnerService.showSpinner();
        this.marketplaceProductService.getOrderForm(this.productId, this.programId, this.tradeDeskId).subscribe(res => {
            this.formTemplateRef.resetForm();
            this.initFormWithDetails(res, false);
            this.spinnerService.hideSpinner();
        },
            err => {
                window.alert('Error ocurred loading new order form.');
                this.spinnerService.hideSpinner();
            }
        );
    }
    // helper method implemented to trigger disabling/enabling of form from with some exceptions)
    disableForm(disable = true, exceptions: string[] = []) {
        const form = this.orderForm.getValue();
        if(disable) {
            form.disable({emitEvent: false});
            form.updateValueAndValidity();
        } else {
            form.enable({emitEvent: false});
            form.updateValueAndValidity();
        }
        if(exceptions.length > 0) {
            for(const controlName of exceptions) {
                if(form.get(controlName)) {
                    if(disable) {
                        form.get(controlName).enable({emitEvent: false});
                    } else {
                        form.get(controlName).disable({emitEvent: false});
                    }
                }
            }
        }
        this.orderForm.next(form);
    }

    submitForm(form: FormGroup) {
        // special handling for dates that are stored as localDate's on the backend.
        if(form.get('orderReceiptDate').value != null) {
            const ibdReceiptDate = new Date(form.get('orderReceiptDate').value);
            form.get('orderReceiptDate').setValue(ibdReceiptDate.toISOString().substring(0, 10));
        }
        if(form.get('prospectusAcknowledgmentDate').value != null) {
            const prospAckDate = new Date(form.get('prospectusAcknowledgmentDate').value);
            form.get('prospectusAcknowledgmentDate').setValue(prospAckDate.toISOString().substring(0, 10));
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        this.spinnerService.showSpinner();
        this.marketplaceProductService.submitOrder(form).subscribe((res: OrderDetails) => {
            if (res.orderApprovalResults[0]?.approvalType === 'KYC' && res.orderApprovalStatus !== 'APPROVED' ) {
                if (this.orderId != null) {
                    dialogConfig.data = {
                        configOptions: this.configOptions,
                        status: 'failed',
                        orderResponse: res,
                        title: `Updated order ${res.orderId}`,
                        message: `Your order has been updated, but is still rejected and will not be processed due to an account restriction.
                        Please view the order in the 'Approval Status' tab for Order ID ${res.orderId} to make necessary corrections,
                        or contact your fixed income trading desk with the reference Order ID`
                    };
                } else {
                    dialogConfig.data = {
                        configOptions: this.configOptions,
                        status: 'failed',
                        orderResponse: res,
                        title: 'Order Submission Unapproved',
                        message: `Your order has been rejected and will not be processed due to an account restriction.
                        Please select “View Order” for Order ID ${res.orderId} to make necessary corrections, or contact your fixed income trading desk with the reference Order ID`,
                        newOrder: true
                    };
                }
            } else {
                if (this.orderId != null) {
                    dialogConfig.data = {
                        configOptions: this.configOptions,
                        status: 'success',
                        orderResponse: res,
                        title: 'Order Updated',
                        message: `Order ${res.orderId} Updated.`
                    };
                } else {
                    dialogConfig.data = {
                        configOptions: this.configOptions,
                        status: 'success',
                        orderResponse: res,
                        title: 'Success',
                        message: `Your order has been submitted successfully. Please reference Order ID ${res.orderId} for tracking or review purposes.`,
                        newOrder: true
                    };
                }
            }
            this.updateForm(res.orderId);
            this.spinnerService.hideSpinner();
            const dialogRef = this.dialog.open(OrderFormDialogComponent, dialogConfig);
            dialogRef.afterClosed()
                .subscribe((result) => {
                    if (result === 'newOrder') {
                        this.newOrder();
                    } else if (result === 'viewOrder') {
                        this.selectedTab = 2;
                    }
                    this.orderId = res.orderId;
                });
        }, error => {
            if(form.get('orderReceiptDate').value != null) {
                const ibdReceiptDate = new Date(form.get('orderReceiptDate').value);
                form.get('orderReceiptDate').setValue(ibdReceiptDate);
            }
            if(form.get('prospectusAcknowledgmentDate').value != null) {
                const prospAckDate = new Date(form.get('prospectusAcknowledgmentDate').value);
                form.get('prospectusAcknowledgmentDate').setValue(prospAckDate);
            }
            if (this.orderId != null) {
                dialogConfig.data = {
                    status: 'failed',
                    orderResponse: error,
                    title: 'Unable To Update Order',
                    message: error.message
                };
            } else {
                dialogConfig.data = {
                    status: 'failed',
                    orderResponse: error,
                    title: 'Unable To Place Order',
                    message: error ? error.message : null
                };
            }
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        });
    }

    submitNetWorth(form: FormGroup) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.autoFocus = true;
        this.spinnerService.showSpinner();
        this.marketplaceProductService.submitOrder(form).subscribe((res: OrderDetails) => {
            this.updateForm(res.orderId);
            dialogConfig.data = {
                status: 'success',
                orderResponse: res,
                title: 'Net Worth Updated',
                message: `Net Worth for order ${res.orderId} sucessfully updated.\nRules will re-execute with an advisor entered net worth of ${res.adviserEntreredNetWorth}`
            };
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        }, error => {
            dialogConfig.data = {
                status: 'failed',
                orderResponse: error,
                title: 'Unable To Update Net Worth',
                message: error.message
            };
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        });
    }
    submitDebitOverride(form: FormGroup) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.autoFocus = true;
        this.spinnerService.showSpinner();
        this.marketplaceProductService.submitOrder(form).subscribe((res: OrderDetails) => {
            this.updateForm(res.orderId);
            dialogConfig.data = {
                status: 'success',
                orderResponse: res,
                title: 'Debit Override Updated',
                message: `A debit balance on order ${res.orderId} will no longer stop it from being approved.
                 Reason for override: ${res.debitBalanceOverrideSelect || res.debitBalanceOverrideSelectOther}`
            };
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        }, error => {
            dialogConfig.data = {
                status: 'failed',
                orderResponse: error,
                title: 'Unable To Update Debit Balance',
                message: error.message
            };
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        });
    }

    submitOverride(form: FormGroup, overrideStatus: boolean) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.autoFocus = true;
        this.spinnerService.showSpinner();
        this.marketplaceProductService.submitOrder(form).subscribe((res: OrderDetails) => {
            this.updateForm(res.orderId);
            if (overrideStatus === true) {
                dialogConfig.data = {
                    status: 'success',
                    orderResponse: res,
                    title: 'Override Successful',
                    message: `The rules execution will now be overridden for order ${res.orderId}. The trade will move to an approved state.`
                };
            } else {
                dialogConfig.data = {
                    status: 'success',
                    orderResponse: res,
                    title: 'Override Removed',
                    message: `The trade approval rules result will be affected accordingly.`
                };
            }
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        }, error => {
            if (overrideStatus === true) {
                dialogConfig.data = {
                    status: 'failed',
                    orderResponse: error,
                    title: 'Unable To Remove Override',
                    message: error.message
                };
            } else {
                dialogConfig.data = {
                    status: 'failed',
                    orderResponse: error,
                    title: 'Unable To Override',
                    message: error.message
                };
            }
            this.spinnerService.hideSpinner();
            this.dialog.open(OrderFormDialogComponent, dialogConfig);
        });
    }

    addOrReplaceFile(file: File) {
        this.fileAddingAction = true;
        this.marketplaceProductService.uploadOrderDocument(file, this.tradeDeskId).subscribe((res: OrderFile) => {
            this.fileAddingAction = false;
            this.updateCopyFormBool = false;
            const form = this.orderForm.getValue();
            const documents = form.get('orderDocuments') as FormArray;
            documents.push(this.fb.group(new OrderFileForm(new OrderFile(res))));
            this.orderForm.next(form);
        }, err => {
            this.fileAddingAction = false;
            alert('Unable to upload file ' + file.name);
        });
    }

    deleteFile(file: OrderFile, index: number) {
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.data = {
            message: `Are you sure you want to delete the file ${file.documentFileName}?`,
            title: 'Delete File',
        };
        const dialogRef = this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
        dialogRef.afterClosed()
            .subscribe((confirmed) => {
                if (confirmed === 'confirm') {
                    this.updateCopyFormBool = false;
                    const form = this.orderForm.getValue();
                    const documents = form.get('orderDocuments') as FormArray;
                    documents.removeAt(index);
                    this.orderForm.next(form);
                }
            });
    }

    canEdit() {
        return !this.waitingOnApiCall && (this.data.editOrders || this.orderId == null);
    }

    ngOnDestroy() {
        this.alive = false;
        if (this.buyersSub) {
            this.buyersSub.unsubscribe();
        }
    }
}
