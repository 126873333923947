import jwt_decode, { JwtPayload } from 'jwt-decode';
import { MarketplaceUserProductsPermissions } from '@common/services/marketplace-product.service';
import { WhiteLabelInfo } from '@common/models/white-label-info';

export class User {
  id: string;
  name: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  company?: string;
  logoutUrl?: string;
  uuid: string;
  primarySplitId?: string;
  splitIds?: string[];
  permissions: string[] = [];
  preferences: object = {};
  userDisplayConfig: UserDisplayConfig = {};
  token: string;
  refreshToken?: string;
  cst?: string;
  trainingData: MarketplaceUserProductsPermissions;
  whiteLabelInfo: WhiteLabelInfo;
  readableExpTime: Date;
  // hack from 3/24/2022: wholesaler needs to be determined for if conditions on api calls.
  isWholesaler: boolean;
  pricingToolType: string[];

  private jwt: Jwt;

  constructor(token: string) {
    this.setJWT(token);
  }

  setJWT(token) {
    this.token = token;
    this.jwt = jwt_decode(token);
    this.id = this.jwt.user_name;
    this.firstName = this.jwt.firstName;
    this.lastName = this.jwt.lastName;
    this.name = this.jwt.name;
    this.company = this.jwt.company;
    this.uuid = this.jwt.uuid;
    this.primarySplitId = this.jwt.primarySplitId;
    this.splitIds = this.jwt.splitIds;
  }

  hasJwtPermission(jwtPermission: string) {
    return this.jwt?.permissions?.includes(jwtPermission);
  }

  isLoggedIn(): boolean {
    if (this.token) {
      const expiration = this.refreshToken ? (jwt_decode(this.refreshToken) as JwtPayload).exp : this.jwt.exp;
      return Date.now() + 10000 < expiration * 1000;  // padding 10 secands to properly handle logout on session expiration
    }                                                 // see userService.initWatchForSessionTimeout()
    return false;
  }

  canAccess(widget: string): boolean {
    return this.permissions.some(x => x?.toLowerCase() === widget?.toLowerCase());
  }
}

interface Jwt {
  user_name: string;
  exp: number;
  name?: string;
  company?: string;
  uuid?: string;
  firstName?: string;
  lastName?: string;
  permissions: string[];
  primarySplitId?: string;
  splitIds?: string[];
}

interface UserDisplayConfig {
  deskId?: number;
  dailySummaryOccurrence?: string;
  displayProductSymbol?: boolean;
  displayBloombergTicker?: boolean;
  displayExternalStructureName?: boolean;
  hideDashboardWidgets?: boolean;
}
