import { ProductUploadType } from '@common/models/enums/product-upload.type';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DesksAndPrograms } from '@common/models/desks-and-programs';
import { MarketplaceOrderData } from '@product-marketplace/common/models/marketplace-order';
import { FormGroup } from '@angular/forms';
import { OrderPreferences } from 'src/app/company-modules/marketplace-settings/order-preferences/order-preferences-model';
import { clearingHouse } from '@common/models/enums/clearingHouse';
import { DeskProductPricing } from 'src/app/company-modules/marketplace-settings/markup-settings/desk-product-pricing.model';
import { ExportOrdersRequest } from '@common/models/export-orders-request';
import { BookBuildingApprovalPostPayload } from '@product-marketplace/structured-products/order-purchase/book-building-approval/book-building-approval.component';
import { STRUCTURED_PRODUCTS_PRODUCT_TYPES } from '@product-marketplace/structured-products-constants';
import { ANNUITY_PRODUCT_TYPES } from '@product-marketplace/annuity-product/annuity-constants';
import { User } from '@common/models/user';
import { DocumentDetails } from '@product-marketplace/common/models/document-details';
import { OrderApprovalHierarchy } from '../../company-modules/marketplace-settings/approval-assignments/order-approval-hierarchy.model';
import { MockApprovalHierarchy } from '../../company-modules/marketplace-settings/approval-assignments/mock-Approval-hierarchy';
import { AccountNumberOption } from '@product-marketplace/structured-products/order-purchase/typeahead-account-number/account-number-option.model';
// import { mockMarketplaceGridData } from '@product-marketplace/structured-products/mock-data/mock-marketplace-grid-products-payload';
// import { mockMarketplaceProductPayload } from '@product-marketplace/structured-products/mock-data/mock-marketplace-product-payload';
// import { mockMarketplaceGridData } from '@product-marketplace/structured-products/mock-data/mock-marketplace-grid-products-payload';

export enum AccountUploadTypes {
  BASIC = 'BASIC',
  KYC = 'KYC'
}

export enum UserRole {
  REP = 'Registered Representative',
  PM = 'Product Manager'
}


// TODO - Zach Fowles
export class MarketplaceUserProductsPermissions {

  readonly TRAINING_CONSTANTS = {
    PENDING: 'Pending',
    COMPLETED: 'Completed'
  };

  annuityTraining: string;
  cdTraining: string;
  notesTraining: string;
  productTypes: Array<string>;

  isAnnuityOnly(): boolean {
    return this.productTypes.length > 0 && !this.hasAnyProducts(
      STRUCTURED_PRODUCTS_PRODUCT_TYPES.LINKED_CDS,
      STRUCTURED_PRODUCTS_PRODUCT_TYPES.STRUCTURED_NOTES
    );
  }

  isStructuredProductsOnly(): boolean {
    return this.productTypes.length === 0 || !this.hasAnyProducts(
      ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES,
      ANNUITY_PRODUCT_TYPES.DIA_SPIA,
      ANNUITY_PRODUCT_TYPES.FIA,
      ANNUITY_PRODUCT_TYPES.FIXED_RATE,
      ANNUITY_PRODUCT_TYPES.RILA);
  }

  hasCompletedStructuredProductTraining(): boolean {
    return this.isTrainingComplete(this.cdTraining) || this.isTrainingComplete(this.notesTraining);
  }

  // Zach Fowles
  // TODO - We ignore Annuity Training as of right now
  hasCompletedAnnuityTraining(): boolean {
    // return this.isTrainingComplete(this.annuityTraining);
    return true;
  }

  // Zach Fowles
  // TODO - is similar to old logic
  get complete(): boolean {
    if (this.isStructuredProductsOnly()) {
      return this.hasCompletedStructuredProductTraining();
    } else if (this.isAnnuityOnly()) {
      return this.hasCompletedAnnuityTraining();
    }

    return this.hasCompletedStructuredProductTraining() || this.hasCompletedAnnuityTraining();
  }

  private isTrainingComplete(value) {
    return value === this.TRAINING_CONSTANTS.COMPLETED;
  }

  private hasAnyProducts(...products) {
    return products.some(product => this.productTypes.includes(product));
  }
}

@Injectable({
  providedIn: 'root'
})
export class MarketplaceProductService {

  fileRequestOptions = {
    headers: new HttpHeaders({
      Accept: 'application/pdf, application/json',
      'Content-Type': 'application/json'
    }), responseType: 'blob' as 'blob'
  };


  MARKETPLACE_PRODUCT_ROOT = `${environment.hosts.api_gateway}/api/marketplace-product-service`;

  constructor(private http: HttpClient) { }

  getUserByRole(companyKey, role: UserRole) {
    let params = new HttpParams();
    params = params.append('companyKey', companyKey);
    params = params.append('roleName', role);
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/approval-config/product-managers', {params});
  }

  productUpload(file, type: ProductUploadType) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.put(this.MARKETPLACE_PRODUCT_ROOT + '/products/upload/assignment', formData);
  }

  getDesksAndPrograms() {
    return this.http.get<DesksAndPrograms[]>(this.MARKETPLACE_PRODUCT_ROOT + '/product/tradeDesk/program');
  }

  getTradeDeskList(): Observable<string[]> {
    return this.http.get<string[]>(this.MARKETPLACE_PRODUCT_ROOT + '/config/tradeDesk');
  }

  /*
    Marketplace Settings ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */

  getAptConfigForDesk(tradeDeskName: string): Observable<OrderPreferences> {
    return this.http.get<OrderPreferences>(this.MARKETPLACE_PRODUCT_ROOT + `/config/order/${tradeDeskName}`);
  }

  saveAptConfig(formValue: any): Observable<any> {
    return this.http.post<boolean>(this.MARKETPLACE_PRODUCT_ROOT + `/config/order/save`, formValue);
  }

  getClearingHouseConfigForDesk(tradeDeskName) {
    // return of(mockPershingExportSettings);
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution/${tradeDeskName}`);
  }

  postClearingHouseConfig(data) {
    return this.http.post(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution`, data);
  }

  getDeskProductPricing(tradeDeskName: string) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/desk-config/product/pricing/${tradeDeskName}`);
    // return of(MockDeskPricingPayload); // for testing
  }

  postDeskProductPricing(data: DeskProductPricing) {
    return this.http.post(`${this.MARKETPLACE_PRODUCT_ROOT}/config/desk-config/product/pricing`, data);
  }

  accountFileUpload(file, tradeDesk, uploadType: AccountUploadTypes) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + `/account-data/account-info/${tradeDesk}/${uploadType}`, formData);
  }

  accountFileCurrentFileDownload(tradeDeskName) {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + `/account-data/download/${tradeDeskName}`, { responseType: 'blob' });
  }

  accountFileTemplateDownload(tradeDesk, uploadType: AccountUploadTypes) {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + `/account-data/template/${tradeDesk}/${uploadType}`, { responseType: 'blob' });
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



  /**
   * Structured Products Page ------------------------------------------------------------------------------------
   */
  productComparisonExport(img, productIdentifiers, currencyCodes, detailedCardIndex) {
    // For testing locally running luma-pdf-server node server:
    //   pull down luma-pdf-server and run the application *npm run start-dev
    //   use endpoints pointing to localhost3000
    //   go into the auth interceptor and uncomment `request.url.includes(3000)` in the add token check logic
    // return this.http.post(`http://localhost:3000/api/pdf-service/pdf/product-comparison`, {img, productIdentifiers, currencyCodes, detailedCardIndex},
    //   { responseType: 'blob' });

    return this.http.post(`${environment.hosts.pdf_gateway}/api/pdf-service/pdf/product-comparison`, { img, productIdentifiers, currencyCodes, detailedCardIndex },
      { responseType: 'blob' });
  }

  getProductUploadHistory() {
    return this.http.get<any[]>(`${this.MARKETPLACE_PRODUCT_ROOT}/products/upload/history`);
  }

  updateMarketplaceProducts(data: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put(this.MARKETPLACE_PRODUCT_ROOT + `/product/override`, JSON.stringify(data), { headers });
  }

  getMarketplaceProductsV2(tradeDeskId, user) {
    // return of(mockMarketplaceGridData); // product data as of 8/18/2021
    if (user.isWholesaler) {
      return this.http.get<any[]>(this.MARKETPLACE_PRODUCT_ROOT + `/product/V2/${tradeDeskId}/wholesaler/${user.company}`);
    } else {
      return this.http.get<any[]>(this.MARKETPLACE_PRODUCT_ROOT + `/product/V2/${tradeDeskId}`);
    }
  }


  getTrainingsComplete(): Observable<boolean> {
    return this.http.get<boolean>(this.MARKETPLACE_PRODUCT_ROOT + '/product/training/completed/user/');
  }

  getUsersTraining(): Observable<MarketplaceUserProductsPermissions> {
    return this.http.get<any>(this.MARKETPLACE_PRODUCT_ROOT + '/product/training/user/').pipe(map(trainingAndProducts => {
      const marketplaceUserProductsPermissions = new MarketplaceUserProductsPermissions();
      Object.assign(marketplaceUserProductsPermissions, trainingAndProducts);
      return marketplaceUserProductsPermissions;
    }));
  }

  exportStructuredProductsGridPDF(data: any[], columnIds: string[]) {
    return this.http.post(`${environment.hosts.pdf_gateway}/api/pdf-service/pdf/structured-products`, { data, columnIds },
      { responseType: 'blob' });
  }

  // ------------------------------------------------------------------------------------



  /**
   * Order Management Page _________________________________________________________________________________________
   */

  getOrderHistoryForDesk(tradeDeskName: string, user: User, startDate: string = null, endDate: string = null) {
    let params = new HttpParams();
    if (endDate) {
      params = params.append('tradeDeskName', tradeDeskName).append('startDate', startDate).append('endDate', endDate);
      if (user.isWholesaler) {
        params = params.set('wholesalerName', user.company);
        return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order/historical/wholesaler`, { params });
      } else {
        return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order/historical`, { params });
      }
    } else { // active orders
      if (user.isWholesaler) {
        return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order/${tradeDeskName}/wholesaler/${user.company}`);
      } else {
        return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order/${tradeDeskName}`);
      }
    }
  }

  getOrderReport(tradeDeskName: string): Observable<any> {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/report/orders/kyc/' + tradeDeskName, { responseType: 'blob' });
  }

  getSTPActiveStatusWithPermissions(tradeDeskName) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution/programs/user/stpActive/${tradeDeskName}`);
  }

  getProgramsForExport(tradeDeskName: string, exportType: clearingHouse) {
    let params = new HttpParams();
    params = params.append('clearingExportType', exportType);
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution/${tradeDeskName}/programs`, { params });
  }

  getTomsExportOptionStatus(tradeDeskId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution/toms-active/${tradeDeskId}`);
  }

  // todo: not working??
  getPershingActiveBool(tradeDeskId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order-execution/pershing-export/permission/${tradeDeskId}`);
  }

  getExportOrdersForDesk(exportOrdersRequest: ExportOrdersRequest) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + `/order-execution/clearing-export/orders`, exportOrdersRequest);
  }

  exportOrders(exportOrdersRequest: ExportOrdersRequest): Observable<any> {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + `/order-execution/clearing-export/orders/export`, exportOrdersRequest, { responseType: 'blob' });
  }

  updateBookBuildingProduct(bookBuildingProductUpdate: BookBuildingProductUpdate): Observable<any> {
    return this.http.put(`${this.MARKETPLACE_PRODUCT_ROOT}/order/bookBuilding/update`, bookBuildingProductUpdate);
  }

  // _________________________________________________________________________________________



  /**
   * Order ticket ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   */


  getAccountNumbersTypeahead(tradeDeskId: number, accountNumberSegment: string, responseItems = 50): Observable<AccountNumberOption[]> {
    return this.http.get<AccountNumberOption[]>(this.MARKETPLACE_PRODUCT_ROOT + `/account-data/download/${tradeDeskId}/${accountNumberSegment}/${responseItems}`);
  }

  checkAccountNumber(tradeDeskId: number, accountNumber: string) {
    const formData = new FormData();
    formData.append('tradeDeskId', '' + tradeDeskId);
    formData.append('accountNumber', accountNumber);
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/accountNumber/validate', formData);
  }

  getOrderFormEdit(orderId: number) {
    return this.http.get<MarketplaceOrderData>(this.MARKETPLACE_PRODUCT_ROOT + `/order/edit/${orderId}`);
  }
  getOrderForm(productId: string, programId: number, tradeDeskId: number) {
    // return of(mockBookBuildingLeumiExistingOrder);
    const params = new HttpParams().set('productId', productId).set('programId', programId.toString()).set('tradeDeskId', tradeDeskId.toString());
    return this.http.get<MarketplaceOrderData>(this.MARKETPLACE_PRODUCT_ROOT + '/order/new', { params }).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }

  // order ticket value refresh on programId change.
  refreshValues(productId: string, programId: number, tradeDeskId: number): Observable<any> {
    const params = new HttpParams().set('productId', productId).set('programId', programId.toString()).set('tradeDeskId', tradeDeskId.toString());
    return this.http.get<MarketplaceOrderData>(this.MARKETPLACE_PRODUCT_ROOT + '/order/new/programId', { params });
  }

  deleteOrder(orderId: number) {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + `/order/cancel/${orderId}`);
  }

  submitOrder(form: FormGroup) {
    form.get('quantity').setValue(form.get('quantityEntered').value * form.get('quantityMultiplier').value);
    if (form.get('orderId').value == null) {
      return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order', form.getRawValue());
    } else {
      return this.http.put(this.MARKETPLACE_PRODUCT_ROOT + '/order', form.getRawValue());
    }
  }

  splitOrderAllocations(payload: {originalOrder, newOrders}) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/split', payload);
  }

  getSTPStatusTab(orderId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order-execution/stp/status/${orderId}`);
  }

  resendSTP(orderId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/order-execution/stp/restart/${orderId}`);
  }

  getSTPActiveStatus(tradeDeskId, programId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/order-execution/program/stpActive/${tradeDeskId}/${programId}`);
  }

  postBasicOrderApprovalUpdate(orderId: number, approvalStatus: string, orderNotes: string) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/approval/basic/', { orderId, orderApprovalResult: approvalStatus || 'PENDING', notes: orderNotes });
  }

  postMultiLevelOrderApprovalUpdate(orderId: number, approvalStatus: string, orderNotes: string) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/approval/multiLevel/', { orderId, orderApprovalResult: approvalStatus || 'PENDING', notes: orderNotes });
  }

  postBookBuildingOrderApprovalUpdate(payload: BookBuildingApprovalPostPayload) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/approval/bookBuilding', { ...payload });
  }

  postBulkOrderCancel(orderIds: number[]) {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/order/cancel', [...orderIds]);
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



  /*
   * Wholesaler Settings -----------------------------------------------------------------------------------------------
   */

  getAllWholesalers() {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-config`);
  }

  getActiveWholesalers() {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-config/active`);
  }

  saveWholesalerDefinition(wholesaler) {
    return this.http.post(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-config`, wholesaler);
  }

  getWholesalerMapping(tradeDeskName) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-mapping/${tradeDeskName}`);
  }

  saveWholesalerMapping(data) {
    return this.http.post(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-mapping`, data);
  }

  getIssuers() {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-mapping/issuers`);
  }

  // ---------------------------------------------------------------------------------------



  /*
    Bulk Order Upload _______________________________________________________________________________
  */
  getBulkOrderTemplate(tradeDeskName) {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/bulkorders/template/' + tradeDeskName, { responseType: 'blob' });
  }

  uploadBulkOrderFile(file: File, tradeDeskName: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + `/bulkorders/upload/${tradeDeskName}`, formData);
  }

  getBulkOrderShown(tradeDeskName) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/bulkorders/enabled/${tradeDeskName}`);
  }
  // _______________________________________________________________________________



  /**
   * Product Disclosure Documents ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   */

  getDisclosureDocuments(tradeDeskName: string) {
    return this.http.get<any[]>(this.MARKETPLACE_PRODUCT_ROOT + `/desk/document/all/${tradeDeskName}`);
  }

  getProductDocumentLinks(tradeDeskName: string, securityId: string, productType: string, productId: string): Observable<DocumentDetails[]> {
    let params = new HttpParams();
    params = params.append('tradeDeskName', tradeDeskName);
    params = params.append('securityId', securityId);
    params = params.append('productType', productType);
    params = params.append('productID', productId);
    return this.http.get<DocumentDetails[]>(`${this.MARKETPLACE_PRODUCT_ROOT}/document/product/links`, { params });
  }

  getDocument(url: string) {
    return this.http.get(`${environment.hosts.api_gateway}${url}`, this.fileRequestOptions);
  }
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~



  /**
   * Order Documents -----------------------------------------------------------------------------------------------
   */

  uploadOrderDocument(file: File, tradeDeskId: number) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tradeDeskId', tradeDeskId.toString());
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/desk/document/order', formData);
  }

  deleteOrderDocument(documentKey: string) {
    return this.http.delete(this.MARKETPLACE_PRODUCT_ROOT + `/desk/document/order/${documentKey}`);
  }

  getDocumentByKey(key: string) {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/desk/document/key/' + key, this.fileRequestOptions);
  }
  // -----------------------------------------------------------------------------------------------



  // unused?
  getDesksThatUseKYC() {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/report/tradeDesks/kyc');
  }

  // unused?
  getWholesalersForSettlementCounterparty() {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/config/wholesaler-config/active/names`);
  }


  // unused?
  checkSTPForOrder(orderId) {
    return this.http.get(`${this.MARKETPLACE_PRODUCT_ROOT}/stp/restart/eligible/${orderId}`);
  }

  // todo: delete these once document disclosure is moved to company admin   vvvvv

  uploadDisclosureDocument(file, data, key = null) {
    let endpoint = this.MARKETPLACE_PRODUCT_ROOT + '/desk/document';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('tradeDeskName', data.tradeDeskName);
    formData.append('displayValue', data.displayValue);
    formData.append('productType', data.productType);
    if (key) {
      endpoint += `/${key}`;
    }
    return this.http.post(endpoint, formData);
  }

  deleteDisclosureDocument(documentKey: string, tradeDeskName: string) {
    return this.http.delete<any[]>(this.MARKETPLACE_PRODUCT_ROOT + `/desk/document/${documentKey}`,
      {
        params: {
          tradeDeskName
        }
      });
  }

  // todo: delete these once document disclosure is moved to company admin ^^^^^




  getIsUserWholesaler(company): Observable<boolean> {
    if (!company) {
      return of(false);
    } else {
      return this.http.get<boolean>(this.MARKETPLACE_PRODUCT_ROOT + '/config/wholesaler-config/' + company);
    }

  }

  getDesksAndProgramsForWholesaler(company): Observable<any> {
    return this.http.get<any>(this.MARKETPLACE_PRODUCT_ROOT + '/product/tradeDesk/program/wholesaler/' + company);
  }


  getMultiLevelApprovalAssignments(companyName): Observable<OrderApprovalHierarchy[]> {
    // return of(MockApprovalHierarchy);
    let params = new HttpParams();
    params = params.append('companyKey', companyName);
    return this.http.get<OrderApprovalHierarchy[]>(this.MARKETPLACE_PRODUCT_ROOT + '/approval-config', {params: params});
  }

  getMultiLevelApprovalTemplate() {
    return this.http.get(this.MARKETPLACE_PRODUCT_ROOT + '/approval-config/template',{ responseType: 'blob' });
  }

  postMultiLevelApprovalAssignments(orderApprovalAssignments: OrderApprovalHierarchy[]): Observable<any> {
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/approval-config/save', orderApprovalAssignments);
  }

  postBulkMultiLevelApprovalAssignments(file, companyName): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('companyKey', companyName);
    return this.http.post(this.MARKETPLACE_PRODUCT_ROOT + '/approval-config/upload', formData);
  }
}

export class BookBuildingProductUpdate {
  oldSecurityId?: string;
  newSecurityId?: string;
  issuerName?: string;
}
