export class AnnuityUserInputModel {
    state: string;
    premium: number;
    age: number;
    gender: 'M' | 'F';
    jointAge: number;
    jointGender: 'M' | 'F';
    allocations: Map<string, number> | {[key: string]: number};
    brokerDealerName: string;
    oesBrokerDealerName: string;

    // ids
    icRider: string[];
    // ids
    dbRider: string[];

    toPost() {
        return {
            ...this,
            allocations: this.formatAllocationsForPost(),
        };
    }

    private formatAllocationsForPost() {
        if (this.allocations instanceof Map) {
            return Object.fromEntries(this.allocations);
        }

        return this.allocations;
    }

}
