import { NavTabType } from '@luma/design-system/lib/components/GlobalNav/GlobalNav.types';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { environment } from '@environments/environment';

export enum MorningstarSecurityType {
  VARIABLE_ANNUITY = 'VA', // VA Contract
  VARIABLE_LIFE = 'VL', // VL Contract
  FIXED_INDEX_ANNUITY = 'IA', // Fixed Index Annuity Contract
  FIXED_ANNUITY = 'FA', // Fixed Annuity Contract
}

export enum MorningstarToolType {
  EXPENSE_ANALYZER = 'EA',
  PROFILE = 'Profile',
  COMPARISON = 'Comparison'
}

export class MorningstarUtils {
  static buildTabs = (permissions: string[], gettingRoutePermissions?: boolean): NavTabType[] => {
    const nav: NavTabType[] = [];

    // Build LC
    if (permissions.includes(WidgetPermissions.AccessLearningCenter)) {
      nav.push({
        title: 'LEARN',
        icon: 'graduation-cap',
        permissions: [WidgetPermissions.AccessLearningCenter],
        route: '/learning-center',
      });

      if (gettingRoutePermissions) {
        nav.push({
          title: 'LEARN-SSO',
          icon: 'graduation-cap',
          permissions: [WidgetPermissions.AccessLearningCenter],
          route: '/learning-center/sso',
        });
      }
    }

    // Build Marketplace Tabs
    if (permissions.includes(WidgetPermissions.MenuMarketplaceAnnuity)) {
      const marketplaceTab = {
        title: 'MARKETPLACE',
        icon: 'store',
        children: [
          {
            title: 'Annuities',
            permissions: [
              WidgetPermissions.MenuMarketplaceAnnuity
            ],
            route: '/product-marketplace/annuity-products'
          }
        ]
      };

      if (permissions.includes(WidgetPermissions.MenuMarketplaceAnnuityBuyButton)) {
        marketplaceTab.children.push({
          title: 'Orders',
          permissions: [
            WidgetPermissions.MenuMarketplaceAnnuity
          ],
          route: '/product-marketplace/annuity-product-orders'
        });

        nav.push(marketplaceTab);
        if (permissions.includes(WidgetPermissions.MenuLifecycleManager)
          && permissions.includes(WidgetPermissions.AccessLifecycleManagerAnnuities)) {
          nav.push({
            title: 'LIFECYCLE',
            icon: 'recycle',
            permissions: [
              WidgetPermissions.MenuLifecycleManager,
              WidgetPermissions.AccessLifecycleManagerAnnuities
            ],
            route: '/lifecycle-manager/annuities'
          });

          if (gettingRoutePermissions) {
            nav.push({
              title: 'Annuity Policy Details',
              icon: 'recycle',
              route: '/annuity-policy-details/overview'
            });
          }
        }
      } else if (gettingRoutePermissions) {
        nav.push(marketplaceTab);
      }
    }

    // May need access here - but should not be included in the menu
    // AnnuityExchangeGuard will handle if they should have access or not
    if (gettingRoutePermissions) {
      nav.push({
        title: 'Annuity Exchange',
        route: '/product-marketplace/annuity-exchange'
      });
    }

    return nav;
  }

  static defaultRoute(permissions: string[]): string {
    const route = '/product-marketplace/annuity-products';
    const hasAccess = this.doesUserHaveAccess(permissions, route);
    return hasAccess ? route : null;
  }

  static doesUserHaveAccess(permissions: string[], url: string) {
    const navTabs = this.buildTabs(permissions, true);
    const flattenRoutes = this.flattenRoutes(navTabs);

    // TODO - Long Term - Handle this with Route Snapshot, etc
    if (url.startsWith('/annuity-policy-details/overview')) {
      url = '/annuity-policy-details/overview';
    }
    return flattenRoutes.includes(url);
  }

  private static flattenRoutes(navTabs: NavTabType[]): string[] {
    return navTabs?.flatMap((tab: NavTabType) => tab.children ? this.flattenRoutes(tab.children) : tab.route);
  }

  static buildLink(vendorUniqueIds: string[], toolId: MorningstarToolType, secType: MorningstarSecurityType) {
    const MS_ENVS = {
      QA: 'https://awse2webqa.morningstar.com',
      STG: 'https://awsstghypo.morningstar.com', // our UAT, their Staging
      UAT: 'https://advhypo-uat.morningstar.com', // our Staging, their UAT
      PROD: 'https://advhypo.morningstar.com',
    };

    let server;
    switch (environment.environmentName) {
      case 'prod':
        server = MS_ENVS.PROD;
        break;
      case 'staging':
        server = MS_ENVS.UAT;
        break;
      case 'uat':
        server = MS_ENVS.STG;
        break;
      default:
        server = MS_ENVS.QA;
        break;
    }


    // {contractId}:  shareClassIDs separated by ‘|’ (e.g. VPUSA0002C|VPUSA04AKP)
    // EA: min is 2 - maximum is 2
    // Profile: min is 1 - maximum is 1
    // Comparison: min 2 - maximum is 3
    const contractIds = vendorUniqueIds.join('|');

    return `${server}/awse20/awsemain/MAIReport.aspx?ToolsId=${toolId}&SecurityType=${secType}&ContractId=${contractIds}`;
  }

  // This is a short-term solution
  static showLmSpTab(): boolean {
    return false;
  }
}
