import { Component, Inject, InjectionToken, Input, Optional } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip/tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type IconTooltipData = {tooltipMessage: string, icon?: IconProp, tooltipPosition?: TooltipPosition};
export const ICON_TOOLTIP_DATA = new InjectionToken<IconTooltipData>('ICON_TOOLTIP_DATA');

@Component({
  selector: 'app-icon-tooltip[tooltipMessage]',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss']
})
export class IconTooltipComponent {

  @Input() tooltipMessage: string;
  @Input() iconProp: IconProp = ['far', 'info-circle'];
  @Input() tooltipPosition: TooltipPosition = 'above';


  constructor(@Inject(ICON_TOOLTIP_DATA) @Optional() public componentData: IconTooltipData) {
    this.tooltipMessage = this.tooltipMessage ?? componentData?.tooltipMessage;
    this.iconProp =  componentData?.icon ?? this.iconProp;
    this.tooltipPosition =  componentData?.tooltipPosition ?? this.tooltipPosition;
  }

}
