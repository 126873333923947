import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService as AuthGuard } from '@common/services/auth-guard.service';
import { NotFoundComponent } from '@common/not-found/not-found.component';
import { LoginComponent } from './company-modules/login/login.component';
import { SsoRequiredComponent } from './company-modules/sso-required/sso-required.component';
import { PermissionGuardService } from '@common/services/permission-guard-service';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { AnnuityRouteGuardService } from '@common/services/annuity-route-guard.service';
import { CompanyAdministrationGuard } from './company-modules/company-administration/company-administration.guard';
import { LogoutGuard } from '@common/services/logout-guard.service';
import { LogoutPageComponent } from './company-modules/login/logout/logout-page.component';
import { RedirectToDashboardGuard } from '@common/services/redirect-to-dashboard-guard.service';

const MENU_ADMIN = WidgetPermissions.MenuAdmin;
const MARKETPLACE_SETTINGS = WidgetPermissions.MenuMarketplaceSettings;
const PRODUCT_CENTER = WidgetPermissions.ProductCenter;
const LEARNING_CENTER = WidgetPermissions.AccessLearningCenter;
export const DASHBOARD_REDIRECT = '/dashboard';
const NOTIFICATION_CENTER = WidgetPermissions.MenuNotificationCenter;
const routes: Routes = [
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard, RedirectToDashboardGuard]},
    { path: 'login', component: LoginComponent },
    { path: 'sso-required', component: SsoRequiredComponent },
    { path: 'logout', component: LogoutPageComponent, canActivate: [LogoutGuard] },
    { path: 'dashboard',
        canActivate: [RedirectToDashboardGuard],
        component: RedirectToDashboardGuard,
    },
    {
        path: 'creation-hub',
        loadChildren: () => import('./company-modules/creation-hub/creation-hub.module').then(mod => mod.CreationHubModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'company-administration',
        loadChildren: () => import('./company-modules/company-administration/company-administration.module').then(mod => mod.CompanyAdministrationModule),
        canActivate: [AuthGuard, PermissionGuardService, CompanyAdministrationGuard],
        data: {
            permission: MENU_ADMIN,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
      path: 'creation-hub-ubs',
      loadChildren: () => import('./company-modules/creation-hub-ubs/creation-hub-ubs.module').then(mod => mod.CreationHubUbsModule),
      canActivate: [AuthGuard]
    },
    { path: 'admin', component: DashboardComponent },
    {
        path: 'admin/audit-records',
        loadChildren: () => import('./admin/audit-records/audit-records.module').then(mod => mod.AuditRecordsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/file-upload',
        loadChildren: () => import('./admin/file-upload/admin-file-upload.module').then(mod => mod.AdminFileUploadModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/email-info',
        loadChildren: () => import('./admin/email-info/email-info-overview/email-info-overview.module').then(mod => mod.EmailInfoOverviewModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/wholesaler-definition',
        loadChildren: () => import('./admin/wholesaler-definition/wholesaler-definition.module').then(mod => mod.WholesalerDefinitionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/wholesaler-mapping',
        loadChildren: () => import('./admin/wholesaler-mapping/wholesaler-mapping.module').then(mod => mod.WholesalerMappingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/find-rfq',
        loadChildren: () => import('./admin/find-rfq/find-rfq.module').then(mod => mod.FindRFQModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/company-administration-relationship-mapping',
        loadChildren: () => import('./admin/company-relationship-mapping/company-relationship-mapping.module').then(mod => mod.CompanyRelationshipMappingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/company-administration-configuration',
        loadChildren: () => import('./admin/company-configuration/company-configuration.module').then(mod => mod.CompanyConfigurationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/market-data-maintainence',
        loadChildren: () => import('./admin/market-data-maintainence/market-data-maintainence.module').then(mod => mod.MarketDataMaintainenceModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: MENU_ADMIN,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'admin/holiday-maintenance',
        loadChildren: () => import('./admin/holiday-maintenance/holiday-maintenance.module').then(mod => mod.HolidayMaintenanceModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: MENU_ADMIN,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'admin/issuer-confirmation',
        loadChildren: () => import('./admin/issuer-confirmation/issuer-confirmation.module').then(mod => mod.IssuerConfirmationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/product-maintenance',
        loadChildren: () => import('./admin/product-maintenance/product-maintenance.module').then(mod => mod.ProductMaintenanceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/email-config',
        loadChildren: () => import('./admin/email-config/email-config.module').then(mod => mod.EmailConfigModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'lifecycle-manager',
        loadChildren: () => import('./company-modules/lifecycle-manager/lifecycle-manager-overview/lifecycle-manager-overview.module').then(mod => mod.LifecycleManagerOverviewModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'notification-center',
        loadChildren: () => import('./company-modules/notification-center/notification-center.module').then(mod => mod.NotificationCenterModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: NOTIFICATION_CENTER,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'product-details',
        loadChildren: () => import('./company-modules/product-details/product-details-overview/product-details-overview.module').then(mod => mod.ProductDetailsOverviewModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'annuity-policy-details',
        loadChildren: () => import('./company-modules/annuity-policy-details/annuity-policy-details.module').then(mod => mod.AnnuityPolicyDetailsModule),
        canActivate: [AuthGuard, AnnuityRouteGuardService]
    },
    {
        path: 'product-marketplace/wholesaler-mapping',
        loadChildren: () => import('./admin/wholesaler-mapping/wholesaler-mapping.module').then(mod => mod.WholesalerMappingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'product-marketplace',
        loadChildren: () => import('./company-modules/product-marketplace/product-marketplace.module').then(mod => mod.ProductMarketplaceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'learning-center',
        loadChildren: () => import('./company-modules/learning-center/learning-center.module').then(mod => mod.LearningCenterModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: LEARNING_CENTER,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'marketplace-settings',
        loadChildren: () => import('./company-modules/marketplace-settings/marketplace-settings.module').then(mod => mod.MarketplaceSettingsModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: MARKETPLACE_SETTINGS,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'product-upload',
        loadChildren: () => import('./company-modules/product-center/product-center.module').then(mod => mod.ProductCenterModule),
        canActivate: [AuthGuard, PermissionGuardService],
        data: {
            permission: PRODUCT_CENTER,
            redirect: DASHBOARD_REDIRECT
        }
    },
    {
        path: 'downloadMarketing',
        loadChildren: () => import('./common/download-link/download-link.module').then(mod => mod.DownloadLinkModule),
        canActivate: [AuthGuard]
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
